/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Loading from "../Common/Loading";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage, getFileUrl } from "../../constants/firebase";
import { Timestamp } from 'firebase/firestore';
import { uploadBytes, ref } from "firebase/storage";
import "nouislider/distribute/nouislider.css";



const CampaignDetails = () => {
    const [loaded, setLoaded] = useState(true);
    const [title, setTitle] = useState("");
    const [adContent, setAdContent] = useState("");
    const [media, setMedia] = useState(null);
    const [mediaName, setMediaName] = useState("");
    const [mediaURL, setMediaURL] = useState("");
    const [link, setLink] = useState(null);
    const [active, setActive] = useState(true);
    const [ageGroups, setAgeGroups] = useState([]);
    const [industrySegments, setIndustrySegments] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [maxViewPerDay, setMaxViewPerDay] = useState(1);
    const [maxClickPerDay, setMaxClickPerDay] = useState(1);
    const [industrySegmentOptions, setIndustrySegmentOptions] = useState([]);
    const [country, setCountry] = useState('');
    const [zip, setZip] = useState('');
    const [region, setRegion] = useState('');
    const [radius, setRadius] = useState('');
    const history = useHistory();
    const { id } = useParams();

    const ageGroupsOption = [
        { label: '13-17 YRS', value: '13-17 YRS' },
        { label: '18-24 YRS', value: '18-24 YRS' },
        { label: '25-34 YRS', value: '25-34 YRS' },
        { label: '35-44 YRS', value: '35-44 YRS' },
        { label: '45-54 YRS', value: '45-54 YRS' },
        { label: '55-64 YRS', value: '55-64 YRS' },
        { label: '65+ YRS', value: '65+ YRS' }
    ];

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setLoaded(false);

        const docRef = doc(db, "campaigns", id);
        getDoc(docRef).then(docSnap => {
            if (docSnap.exists() && isMounted) {
                const data = docSnap.data();
                setTitle(data.title);
                setAdContent(data.adContent);
                setLink(data.link);
                setActive(data.active);
                setMediaName(data.mediaName);
                setAgeGroups(data.ageGroups);
                setIndustrySegments(data.industrySegments ? data.industrySegments : []);
                setStartDate(data.startDate);
                setEndDate(data.endDate);
                setMaxViewPerDay(data.maxViewPerDay);
                setMaxClickPerDay(data.maxClickPerDay);
                setCountry(data.country);
                setZip(data.zip);
                setRegion(data.region);
                setRadius(data.radius);
                getFileUrl(data.mediaName, (url) => {
                    setMediaURL(url)
                });
                setLoaded(true);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        });

        let doc_id = "lists";
        const configRef = doc(db, "config", doc_id);

        let iso = [];
        getDoc(configRef).then(resRef => {
            if (resRef.exists() && isMounted) {
                let data = resRef.data();

                data.industry_segments.forEach((row) => {
                    let newElement = {
                        label: row.label,
                        value: row.label,
                    };
                    iso.push(newElement);
                });

            }
        });
      
        setIndustrySegmentOptions(iso);
        return () => { isMounted = false };
    }, []);


    const handleChange = (e) => {
        switch (e.target.name) {
            case "title":
                setTitle(e.target.value);
                break;
            case "adContent":
                setAdContent(e.target.value);
                break;
            case "link":
                setLink(e.target.value);
                break;
            case "active":
                setActive(JSON.parse(e.target.value));
                break;
            case "media":
                setMedia(e.target.files[0]);
                break;
            case "startDate":
                setStartDate(e.target.value);
                break;
            case "endDate":
                setEndDate(e.target.value);
                break;
            case "maxViewPerDay":
                setMaxViewPerDay(e.target.value);
                break;
            case "maxClickPerDay":
                setMaxClickPerDay(e.target.value);
                break;
            case "country":
                setCountry(e.target.value);
                break;
            case "zip":
                setZip(e.target.value);
                break;
            case "region":
                setRegion(e.target.value);
                break;
            case "radius":
                setRadius(e.target.value);
                break;
            default:
                return;
        }
    };

    const handleChangeMultiBox = (values) => {
        setIndustrySegments(values);
    };
    const handleChangeRange = (values) => {
        setAgeGroups(values);
    };

    const radios = [
        { name: 'Male', value: 'male' },
        { name: 'Female', value: 'female' }
    ];

    const handleUpdate = () => {
        //media
        let media_name = '';
        if (media) {
            media_name = media.name;
            const storageRef = ref(storage, media.name);
            uploadBytes(storageRef, media).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });
        }
        setLoaded(false);
        if (title.length > 1 && link.length > 1 && startDate.length > 1 && endDate.length > 1) {
            const adDocRef = doc(db, 'campaigns', id)
            updateDoc(adDocRef, {
                title,
                adContent,
                link,
                active,
                ageGroups,
                mediaName: media_name ? media_name : mediaName,
                industrySegments,
                startDate,
                endDate,
                maxViewPerDay,
                maxClickPerDay,
                country,
                zip,
                region,
                radius,
                created: Timestamp.now()
            });

            history.push("/ad-campaigns");

        } else {
            alert('There was a a problem creating that job. Does one exist with that title?');
        };
        // setTimeout(() => { setLoaded(true); }, 1000);
    }

    return loaded ? (
        <Fragment>
            <div className="justify-content-center  align-items-center h-80 w-100">
                <div className="form-input-content text-center error-page">
                    <h1 className="font-weight-bold mb-5">Update Ad Campaign</h1>
                    <div className={"card p-5  w-lg-50  m-auto text-left"}>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Ad Title</strong>
                            </label>
                            <input type="text"
                                defaultValue={title}
                                onChange={handleChange} className="form-control w-100" name="title" />
                        </div>
                        <div className="form-group">
                            <label className="text-blackl">Ad Content</label>
                            <textarea
                                className="p-2 mt-2 form-control w-100 fs-12"
                                style={{ height: "100px" }}
                                defaultValue={adContent}
                                name="adContent"
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Media</strong>
                            </label>
                            <br />
                            {mediaURL ? <img src={mediaURL} alt="" height={"80px"} /> : ""}
                            <input type="file" onChange={handleChange} className="form-control w-100" name="media" />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Link</strong>
                            </label>
                            <input type="text"
                                defaultValue={link}
                                onChange={handleChange} className="form-control w-100" name="link" />
                        </div>
                        <div className="form-group">
                            <label className="text-black"><strong>Active:</strong></label>
                            <select className="form-control w-100" id="sel1" defaultValue={active} name="active" onChange={handleChange}>
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Age groups</strong>
                            </label>
                            <MultiSelect
                                options={ageGroupsOption}
                                value={ageGroups}
                                onChange={handleChangeRange}
                                labelledBy="Age Groups"
                                name="ageGroups"
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Industry Segments</strong>
                            </label>
                            <MultiSelect
                                options={industrySegmentOptions}
                                value={industrySegments}
                                onChange={handleChangeMultiBox}
                                labelledBy="Industry Segments"
                                name="industrySegments"
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Start Date</strong>
                            </label>
                            <input type="date"
                                defaultValue={startDate}
                                onChange={handleChange} className="form-control w-100" name="startDate" />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>End Date</strong>
                            </label>
                            <input type="date"
                                defaultValue={endDate}
                                onChange={handleChange} className="form-control w-100" name="endDate" />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Max View Per Day</strong>
                            </label>
                            <input type="number"
                                defaultValue={maxViewPerDay}
                                onChange={handleChange} className="form-control w-100" name="maxViewPerDay" />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Max Click Per Day</strong>
                            </label>
                            <input type="number"
                                defaultValue={maxClickPerDay}
                                onChange={handleChange} className="form-control w-100" name="maxClickPerDay" />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Country</strong>
                            </label>
                            <input type="text" defaultValue={country} onChange={handleChange} className="form-control w-100" name="country" />
                        </div>

                        <div className="form-group">
                            <label className="text-black">
                                <strong>Zip/Postal Code</strong>
                            </label>
                            <input type="text" defaultValue={zip} onChange={handleChange} className="form-control w-100" name="zip" />
                        </div>

                        <div className="form-group">
                            <label className="text-black">
                                <strong>Region/State</strong>
                            </label>
                            <input type="text" defaultValue={region} onChange={handleChange} className="form-control w-100" name="region" />
                        </div>

                        <div className="form-group">
                            <label className="text-black">
                                <strong>Radius</strong>
                            </label>
                            <input type="text" defaultValue={radius} onChange={handleChange} className="form-control w-100" name="radius" />
                        </div>

                        <Button onClick={handleUpdate} className={"text-uppercase mt-4"}>Update</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    ) : <Loading />;
};

export default CampaignDetails;
