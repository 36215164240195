import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import swal from "sweetalert";

const style = {
    width: '40%'
};

const CampaignList = ({ data }) => {

    const handleDelete = async (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this campaign!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then(async (willDelete) => {
			if (willDelete) {
                const adDocRef = doc(db, 'campaigns', id)
                try {
                    await deleteDoc(adDocRef)
                } catch (err) {
                    alert(err)
                }
			}
		});
    }
    console.log(data)

    return (
        <Card >
            <Card.Body>
                <Card.Title>Ad Campaigns</Card.Title>
                <div className="w-100 mx-0 mx-md-auto row justify-content-center">
                    <div id="list" className="display w-100 dataTable">
                        <div id="list_wrapper" className="dataTables_wrapper">
                            {data.length > 0 ?
                                <table id="list" className="display w-100 dataTable">
                                    <thead>
                                        <tr role="row">
                                            <th style={style}>Title</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((ad, i) => {
                                            const data = ad.data
                                            return (
                                                <tr key={i + "qli"}>
                                                    <td><Link to={`/ad-campaigns/${ad.id}`}  >{data.title} </Link></td>
                                                    <td>{data.startDate && typeof data.endDate === "string" ? data.startDate : '-'}</td>
                                                    <td>{data.endDate && typeof data.endDate === "string" ? data.endDate : '-'}</td>
                                                    <td>{data.active ? "Active" : 'Paused'}</td>
                                                    <td >
                                                        <button className="btn btn-primary" onClick={() => handleDelete(ad.id)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> : <h5 className="text-muted mt-4">No data to display.</h5>}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );

}

export default CampaignList;