/* eslint-disable */
import { Modal, ListGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loading from "../Common/Loading";
import { db, storage, getFileUrl } from "../../constants/firebase";
import {collection, addDoc, deleteDoc, doc, getDocs} from 'firebase/firestore';
import {  } from "react-router-dom";
import { uploadBytes, ref } from "firebase/storage";

const CourseResources = ({  course_id }) => {

	const [addModal, setAddModal] = useState(false);
	const [resources, setResources] = useState([]);
	const [loaded, setLoaded] = useState(true);

	useEffect(() => {
		
		let isMounted = true;
		getDocs(collection(db, `courses/${course_id}/resources`)).then(resRef => {
			//On load get course resources
			if (isMounted) resRef.forEach((doc) => {
				let newElement = {
					id: doc.id,
					data: doc.data(),
					url: ''
				};

				if(doc.data().type=="file") {
					getFileUrl(doc.data().filename, (url) => {
						newElement.url = url;
					});
				} else {
					newElement.url = doc.data().link;
				}
				setResources(resources => [...resources, newElement]);
			});
		});

		return () => { isMounted = false };
	}, []);

	const ResourceList = () => {

		const trashResource = async (id) => {
			
			const changedRes = resources.filter(function(res) { 
				return res.id !== id
			});
			setResources(changedRes);

			const adDocRef = doc(db, `courses/${course_id}/resources`, id);

			try {
				await deleteDoc(adDocRef)

			} catch (err) {
				alert(err)
			}
		}
		
		return (
			<div>
				<ListGroup>
					{resources.map((resource,index) => {
						return (
							<ListGroup.Item key={index}>
								<i
									className={
										"text-muted mr-3 flaticon-381-" +
										(resource.data.type === "file" ? "archive" : "networking-1")
									}
								></i>{" "}
								<small role="button" onClick={()=> window.open(resource.url, "_blank")}>{resource.data.label}</small>
								<small className="pull-right ">
									<i role="button"  className="flaticon-381-trash-1 indigo text-info" onClick={() => trashResource(resource.id)}></i>
								</small>
							</ListGroup.Item>
						);
					})}
				</ListGroup>
			</div>
		);
	}

	const ResourceAdd = ({ show, onClose}) => {

		const [resource, setResource] = useState({id: Math.floor(Date.now()/1000), label: "", type: "file", file: null, filename: null, link: null});
		
		const handleChange = (e) => {
			switch (e.target.name) {
				case "label":
					resource.label = e.target.value;
					setResource({ ...resource });
					break;
				case "resource":
					resource.type = e.target.value;
					setResource({ ...resource });
					break;
				case "link":
					resource.link = e.target.value;
					setResource({ ...resource });
					break;
				case "file":
					resource.file = e.target.files[0];
					setResource({ ...resource });
					break;
				default:
					break;
			}
		}

		const onSave = async () => {
			if(resource.label && resource.file || resource.link) {
				
				setAddModal(false);
				setLoaded(false);

				let filename = "";
				if(resource.file) {
					const unique_file_name = Math.floor(Date.now()/1000);
					filename = unique_file_name+'.'+ resource.file.name.split('.').pop();
					const storageRef = ref(storage, filename);
					await uploadBytes(storageRef, resource.file);
				}
				let newObj = {
					label: resource.label,
					type: resource.type,
					filename,
					link: resource.link
				};
				let addedRec = await addDoc(collection(db, `courses/${course_id}/resources`), newObj);
				
				let createdElement = {
					id: addedRec.id,
					data: newObj,
					url: ""
				};
				if(filename) {
					getFileUrl(filename, (url) => {
						createdElement.url = url;
					});
				} else {
					createdElement.url = newObj.link;
				}
				setResources(resources => [...resources, createdElement]);
				setLoaded(true);
			} else {
				alert('There wasa a problem creating that resource. Does one exist with that label and file?');
			}
		}

		return show ? (
			<Modal
				show={show}
				dialogClassName="modal-90w"
				aria-labelledby="example-custom-modal-styling-title"
				onClose={onClose}
				size="lg"
				centered
			>
				<Modal.Header
					onHide={onClose}
					closeButton
				>
					<Modal.Title>Add Resource</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="col col-2">
							<label>Label</label>
						</div>

						<div className="col col-10">
							<input
								type="value"
								onChange={handleChange}
								className="form-control"
								placeholder="Label"
								name="label"
							></input>
						</div>
					</div>

					<div className="form-group row">
						<div className="col col-2">
							<label>Type</label>
						</div>

						<div className="col col-10">
							<select
								onChange={handleChange}
								name="resource"
								className="w-100 form-control"
							>
								<option value="file">File - Course Material</option>
								<option value="file">File - Course Transcript</option>
								<option value="link">Link - Resource</option>
							</select>
						</div>
					</div>

					<div className="form-group row">
						<div className="col col-2">
							<label className="text-capitalize">{resource.type}</label>
						</div>
						<div className="col col-10">
							{resource.type=='link' ? <input type="text" className="form-control" placeholder="Link" name="link" onChange={handleChange} /> : <input type="file" name="file" onChange={handleChange} />} 
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer >
					<Button onClick={onClose} className="m-0 btn-outline-primary">
						Close
					</Button>
					<Button variant="primary" onClick={onSave}>
						Save changes
					</Button>
				</Modal.Footer>
			</Modal>
		) : (
			<></>
		);

	}

	return loaded ? (
		<>
			{resources ? <ResourceList /> : ""}

			<ResourceAdd 
				show={addModal} 
				onClose={() => { setAddModal(false) }} 
			/>

			<div className="form-group text-center  mt-2">
				<button
					className="btn btn-sm btn-primary"
					onClick={() => { setAddModal(!addModal); }}
				>
					Add Resource
				</button>
			</div>
		</>
	) : <Loading />;
}
export default CourseResources;