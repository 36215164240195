import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import swal from "sweetalert";

const CourseList = ({ data }) => {

    const handleDelete = async (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this course!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then(async (willDelete) => {
			if (willDelete) {
				const adDocRef = doc(db, 'courses', id)
				try {
					await deleteDoc(adDocRef)
				} catch (err) {
					alert(err)
				}
			}
		});
    }

	return (
		<Card>
			<Card.Body>
				<Card.Title>Courses </Card.Title>
				<div className="w-100 mx-0 mx-md-auto row justify-content-center">
					<div id="list" className="display w-100 dataTable">
						<div id="list_wrapper" className="dataTables_wrapper">
							{data && data.length > 0 ? <table id="list" className="display w-100 dataTable">
								<thead>
									<tr role="row">
										<th>Name</th>
										<th className="text-center">Status</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody>
									{data.map((Course, i) => {
										return (
											<tr key={i + "qli"} className="text-left">
												<td><Link to={"/learn/courses/" + Course.id}>{Course.data.name}</Link></td>
												<td className="text-center">
													{" "}
													<span className="badge badge-success text-capitalize text-white">
														{Course.data.active ? 'Active' : 'deactivate'}
													</span>
												</td>
												<td  className="text-center">
													<button className="btn btn-primary" onClick={() => handleDelete(Course.id)}>
														Delete
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table> : <h5 className="text-muted mt-4">No data to display.</h5>}
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);

}

export default CourseList;