/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {collection, addDoc, Timestamp} from 'firebase/firestore';
import { db, storage } from "../../constants/firebase";
import { uploadBytes, ref } from "firebase/storage";

const NewCategory = () => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [cover, setCover] = useState("");
	const [ishidden, setIshidden] = useState(false);

	const history = useHistory();
	const handleChange = (e) => {
		switch (e.target.name) {
			case "name":
				setName(e.target.value);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			case "cover":
				setCover(e.target.files[0]);
				break;
			case "ishidden":
				setIshidden(prevCheck => !prevCheck);
				break;
			default:
				return;
		}
	}

    const handleCreate = async () => {
		
		//cover_image
		let cover_image = '';
		if (cover) {
			cover_image = cover.name;
			const storageRef = ref(storage, cover.name);
			uploadBytes(storageRef, cover).then((snapshot) => {
				console.log('Uploaded a blob or file!');
			});
		}

        if (name.length > 1 && description.length > 1) {

            await addDoc(collection(db, 'categories'), {
                name: name,
                description: description,
				cover: cover_image,
                active: true,
				ishidden,
                created: Timestamp.now()
            });

            history.push("/learn/categories");
        } else {
            alert('There wasa a problem creating that category. Does one exist with that name?');
        };
    }

	return (
		<Fragment>
			<div className="justify-content-center  align-items-center h-80 w-100">
				<div className="form-input-content text-center error-page">
					<h1 className="font-weight-bold mb-5">Create a Category</h1>
					<div className={"card p-5 w-lg-50 m-auto text-left"}>
						<div className="form-group">
							<label className="text-black">
								<strong>Category Name</strong>
							</label>
							<input
								type="text"
								className="form-control w-100"
								name="name"
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Cover Image</strong>
							</label>
							<input
								type="file"
								onChange={handleChange}
								className="form-control w-100"
								name="cover"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Category Description</strong>
							</label>
							<textarea
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="description"
								style={{ resize: "none" }}
								rows="4"
							/>
						</div>
                        <div className="form-group">
                           <div className="custom-control custom-checkbox mb-3">
                              <input
                                 type="checkbox"
                                 className="custom-control-input"
                                 id="customCheckBox1"
								 onChange={handleChange}
								 name="ishidden"
                              />
                              <label
                                 className="custom-control-label"
                                 htmlFor="customCheckBox1"
                              >
                                 Is Hidden?
                              </label>
                           </div>
                        </div>
						<Button
							className={"text-uppercase"}
							onClick={handleCreate}
						>
							Create
						</Button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NewCategory;
