/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SortArrow from "../../assets/icons/sort-arrow.svg";
import Loading from "../Common/Loading";
import SegmentList from "./SegmentList";
import { Dropdown } from "react-bootstrap";
import { db } from "../../constants/firebase";
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const System = () => {

	const [segments, setSegments] = useState([]);
	const [segments_list, setSegmentsList] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(async () => {
		let isMounted = true;
		const docSnap = await getDoc(doc(db, 'config','lists'))
        
        if (docSnap.exists() && isMounted) {
            const data = docSnap.data();
            await data.industry_segments.map(async (doc) => {
				let newElement = {
					image: doc.image,
                    label: doc.label
				};	
                // let newElement_ = {
				// 	image: doc.image,
                //     label: doc.label,
                //     url: ""
				// };
        
                setSegments(segments => [...segments, newElement]);
                // setSegmentsList(segments_list => [...segments_list, newElement]);
			});
        }
		
		setTimeout(() => { setLoaded(true); }, 1000);
		return () => { isMounted = false };
	}, []);

	return loaded ? (
		<Fragment>
			<div className="p-4">
				<div className="d-flex flex-wrap align-items-center pt-3 mb-4">
					<div className="mr-auto mb-2 pr-2 pb-3">
						<h6 className="text-black fs-16 font-w600 mb-0 d-none">List of Industry Segement</h6>
						<span className="fs-12 text-muted  d-none">
							Showing {segments.length} Results
						</span>
					</div>
					<div className="d-flex  flex-wrap align-items-center ">
						<Link
							to="/segments/new"
							className="mobile-smaller btn btn-primary light btn-sm mr-2 "
						>
							New Industry Segment
						</Link>
					</div>
                    
				</div>
				<SegmentList data={segments}  setSegments={setSegments} />

			</div>
		</Fragment>
	) : <Loading />;
};

export default System;