/* eslint-disable */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SortArrow from "../../assets/icons/sort-arrow.svg";
import CampaignList from "./CampaignList";
import { Dropdown } from "react-bootstrap";
import Loading from "../Common/Loading";
import { db } from "../../constants/firebase";

import { orderBy, collection, query, onSnapshot } from 'firebase/firestore';


const AdCampaigns = () => {

    const [campaigns, setCampaigns] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const isMounted = useRef();
    useEffect(() => {
        isMounted.current = true
        const q = query(collection(db, 'campaigns'), orderBy('created', 'desc'))
        onSnapshot(q, (querySnapshot) => {
            if (isMounted.current) setCampaigns(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        });

        setTimeout(() => { setLoaded(true); }, 2000);
        return () => { isMounted.current = false };
    }, []);

    return loaded ? (
        
            <Fragment>
                <div className="p-4">
                    <div className="d-flex flex-wrap align-items-center pt-3 mb-4">
                        <div className="mr-auto mb-2 pr-2 pb-3">
                            <h6 className="text-black fs-16 font-w600 mb-0 d-none"></h6>

                            <span className="fs-12 text-muted  d-none">
                                Showing {campaigns.length} Results
                            </span>
                        </div>
                        <div className="d-flex  flex-wrap align-items-center ">
                            <Link
                                to="/ad-campaigns/new"
                                className="mobile-smaller btn btn-primary light btn-sm mr-2 "
                            >
                                New Ad
                            </Link>
                        </div>
                        <Dropdown className="dropdown custom-dropdown mb-0 mt-2 mt-sm-0 ml-sm-2">
                            <Dropdown.Toggle
                                variant=""
                                className="mobile-smaller btn btn-sm border border-primary text-black "
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={SortArrow} /> Newest
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                <Dropdown.Item className="dropdown-item" to="/search-data">
                                    Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="dropdown-item text-danger"
                                    to="/search-data"
                                >
                                    Close
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>{" "}
                    </div>
                    <CampaignList data={campaigns} />
                </div>
            </Fragment>

    ) : <Loading />;
};

export default AdCampaigns;