/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loading from "../Common/Loading";
import { db, storage } from "../../constants/firebase";
import { uploadBytes, ref } from "firebase/storage";
import Categories from "./Categories";
import {collection, addDoc, Timestamp, orderBy, query, onSnapshot} from 'firebase/firestore';

const NewCourse = () => {

	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [coverImage, setCoverImage] = useState("");
	const [loaded, setLoaded] = useState(false);
	const history = useHistory();

	useEffect(() => {
		let isMounted = true;
		const q = query(collection(db, 'categories'), orderBy('created', 'desc'));

		onSnapshot(q, (querySnapshot) => {
			if (isMounted) setCategories(querySnapshot.docs.map(doc => ({
				id: doc.id,
				name: doc.data().name,
				data: doc.data()
			})))
		});
		
		setTimeout(() => { if (isMounted) setLoaded(true); }, 1000);
		return () => { isMounted = false };
	}, []);

	const handleChange = (e) => {
		switch (e.target.name) {
			case "name":
				setName(e.target.value);
				break;
			case "coverImage":
				setCoverImage(e.target.files[0]);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			default:
				return;
		}
	};
	


	const catHandleChangeMultiBox = (values) => {
		setSelectedCategories(values);
	};
	const handleCreate = async () => {
        setLoaded(false);
        if (name.length >= 1 && selectedCategories.length >= 1) {

			//cover_image
			let cover_image = '';
			if (coverImage) {
				cover_image = coverImage.name;
				const storageRef = ref(storage, coverImage.name);
				uploadBytes(storageRef, coverImage).then((snapshot) => {
					console.log('Uploaded a blob or file!');
				});
			}
            await addDoc(collection(db, 'courses'), {
                name,
                description,
                categories: selectedCategories,
				pricing: 'paid',
				video: "",
				instructor: [],
				coverImage: cover_image,
                active: true,
                created: Timestamp.now()
            });

            history.push("/learn/courses");
        } else {
            alert('course name and at least one category required to create a course.');
        };
        setTimeout(() => { setLoaded(true); }, 1000);
	}

	return loaded ? (
		<Fragment>
			<div className="justify-content-center  align-items-center h-80 w-100">
				<div className="form-input-content text-center error-page">
					<h1 className="font-weight-bold mb-5">Create a Course</h1>
					<div className={"card p-5  w-lg-50  m-auto text-left"}>
						<div className="form-group">
							<label className="text-black">
								<strong>Course Name</strong>
							</label>
							<input type="text" onChange={handleChange} className="form-control w-100" name="name" />
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Cover Image</strong>
							</label>
							<input
								type="file"
								onChange={handleChange}
								className="form-control w-100"
								name="coverImage"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Course description</strong>
							</label>
							<textarea
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="description"
								style={{ resize: "none" }}
								rows="4"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Category</strong>
							</label>{" "}

							<Categories data={categories} categories={selectedCategories} catHandleChangeMultiBox={catHandleChangeMultiBox} />
						</div>

						<Button onClick={handleCreate} className={"text-uppercase mt-4"}>Create</Button>
					</div>
				</div>
			</div>
		</Fragment>
	) : <Loading />;
};

export default NewCourse;
