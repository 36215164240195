/* eslint-disable */
import React, { useContext } from "react";
import img from "../../assets/images/logo-flux-color.png";
import UserContext from "../../constants/providers/UserProvider";
import { signInWithGoogle } from "../../constants/firebase";

const Login = (props) => {
    const user = useContext(UserContext);

    return (
      <div className="row justify-content-center h-100 align-items-center h-80">
        <div className="col-md-6">
          <div className="authentication-content">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="auth-form bg-white">
                                <div className="text-center pb-5 
                  mb-5 w-100">
                    <img src={img} alt="" className="w-75"/>
                    <p className="mt-4 text-muted small">Community Management Portal</p>
                  </div>

                  <button
                    className="btn btn-dark btn-block google-sign-in"
                    onClick={signInWithGoogle}
                  >
                    <img
                      src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                      className="mr-2"
                    />
                    <span> Continue with Google</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Login;
