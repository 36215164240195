/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import SortArrow from "../../assets/icons/sort-arrow.svg";
import UserList from "./UserList";
import { Dropdown } from "react-bootstrap";
import Loading from "../Common/Loading";
import { db } from "../../constants/firebase";

import { orderBy, collection, query, onSnapshot, limit } from 'firebase/firestore';


const Users = () => {
    
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        let isMounted = true;

        const first = query(collection(db, "users"), orderBy("createdAt", 'desc'), limit(15));
        onSnapshot(first, (querySnapshot) => {
            if (isMounted) setUsers(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        });

        setTimeout(() => { setLoaded(true); }, 1000);
        return () => { isMounted = false };
    }, []);

    return loaded ? (
        
            <Fragment>
                <div className="p-4">
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="mr-auto mb-2 pr-2 pb-3">
                            <h6 className="text-black fs-16 font-w600 mb-0 d-none"></h6>

                            <span className="fs-12 text-muted  d-none">
                                Showing {users.length} Results
                            </span>
                        </div>
                        <div className="d-flex  flex-wrap align-items-center ">
                            {/* <Link
                                to="/careers/post"
                                className="mobile-smaller btn btn-primary light btn-sm mr-2 "
                            >
                                New User
                            </Link> */}
                        </div>
                        {/* <Dropdown className="dropdown custom-dropdown mb-0 mt-2 mt-sm-0 ml-sm-2">
                            <Dropdown.Toggle
                                variant=""
                                className="mobile-smaller btn btn-sm border border-primary text-black "
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={SortArrow} /> Newest
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                <Dropdown.Item className="dropdown-item" to="/search-data">
                                    Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="dropdown-item text-danger"
                                    to="/search-data"
                                >
                                    Close
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                    <UserList users={users} setUsers={setUsers} page={page} setPage={setPage} />
                </div>
            </Fragment>

    ) : <Loading />;
};

export default Users;