/* eslint-disable */
import { v4 as uuidv4 } from "uuid";

const localStorage = window.localStorage;

export const job = {

	add: (title, companyname, description, applylink) => {
		if (title.length > 1 && companyname.length > 1 && description.length > 1 && applylink.length > 1) {
			let jobs = localStorage.getItem("jobs");
			jobs = jobs ? JSON.parse(jobs) : [];

			jobs.push({ id: uuidv4(), title, companyname, description, applylink });

			localStorage.setItem('jobs', JSON.stringify(jobs));
			return true;
		}
	},
	list: () => {
		let jobs = localStorage.getItem("jobs");
		return jobs ? JSON.parse(jobs) : [];
	}
}

export const category = {

	add: (name, description) => {
		if (name.length > 1 && description.length > 1) {
			let categories = localStorage.getItem("categories");
			categories = categories ? JSON.parse(categories) : [];

			categories.push({ id: uuidv4(), name, description });

			localStorage.setItem('categories', JSON.stringify(categories));
			return true;
		}
	},
	list: () => {

		let categories = localStorage.getItem("categories");
		return categories ? JSON.parse(categories) : [];

	}
}


export const course = {
	add: (name, description) => {
		if (name.length > 1 && description.length > 1) {
			let categories = localStorage.getItem("categories");
			categories = categories ? JSON.parse(categories) : [];

			categories.push({ id: uuidv4(), name, description });

			localStorage.setItem("categories", JSON.stringify(categories));
			return true;
		}
	},
	list: () => {
		let categories = localStorage.getItem("categories");
		return categories ? JSON.parse(categories) : [];
	},
};