/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { Link } from 'react-router-dom';
import { db } from "../../constants/firebase";
import {collection, addDoc, Timestamp, orderBy, query, onSnapshot} from 'firebase/firestore';
import Loading from '../Common/Loading';
import fileDownload from 'js-file-download'
import Template from './Newsletter'

const Editor = () => {
  const emailEditorRef = useRef(null);
  const [templateName, setTemplateName] = useState("");
  const [html, setHtml] = useState('');
  const [design, setDesign] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [exportData, setExportData] = useState("");
  const [defaultTemplate, setDefaultTemplate] = useState(null);

  const history = useHistory();

  const saveTemplates = () => {

    if(templateName===""){
      alert("Please add template name");
      return;
    }

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', design);
      setHtml(html);
      setDesign(design);
    });
  };

  useEffect(() => {
    if(design!==""){
      handleCreate();
    }
    
  }, [design])

  const handleCreate =  async () => {
    setLoaded(false);
        await addDoc(collection(db, 'templates'), {
            "name": templateName,
            "html": html,
            "design": JSON.stringify(design),
            created: new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format()
        });
        setTimeout(() => { setLoaded(true); }, 1000);
        history.push('/broadcasts');
  }

  const exportHtml = () => {
    if(templateName===""){
      alert("Please add template name");
      return;
    }
    
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setExportData(html);
    });
  }

  useEffect(() => {
    
    if(exportData!==""){
      setLoaded(false);
      fileDownload(exportData, `${templateName}.html`)
      setLoaded(true);
    }
  }, [exportData])


  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {Template};
    setDefaultTemplate(Template);
    if(defaultTemplate!==null){
      emailEditorRef.current.editor.loadDesign(defaultTemplate);
    }
  }

  useEffect(() =>{
    if(defaultTemplate!=null){
      onLoad();
    }
    
  }, [defaultTemplate] )

  const onReady = () => {
    // editor is ready
    console.log('onReady');
  };

  const handleChange = (e) => {
    setTemplateName(e.target.value);
  }


  return (
    loaded?<div className='email-template-editor'>
      <div className="d-flex flex-wrap pt-3 justify-content-between px-5">
        <div className="d-flex flex-wrap align-items-left pt-3 justify-content-end">
          {/* <label className="text-black">
            <strong>Template Name</strong>
          </label> */}
          <input
            type="text"
            className="form-control w-100 mw-300"
            name="name"
            placeholder='Template Name'
            onChange={handleChange}
            
          />
        </div>
        <div className="d-flex flex-wrap align-items-left pt-3 justify-content-end">
          <Link
              to="/broadcasts"
              className="btn btn-primary "
          >
              Back to Broadcasts
          </Link>
          <div className='d-flex align-items-center'>
            <button className='btn btn-primary mx-3' onClick={saveTemplates}>Save Template</button>
          </div>
          <div className='d-flex align-items-center'>
            <button className='btn btn-primary ' onClick={exportHtml}>Export HTML</button>
          </div>
        </div>
      </div>
      <div className="justify-content-center  align-items-center">
        <div className="form-input-content text-center error-page">
          {/* <h1 className="font-weight-bold mb-5">Create a Tempate</h1> */}
          <div className={"py-4 pl-5 w-lg-50 m-auto text-left"}>
            
            <EmailEditor
              ref={emailEditorRef}
              onLoad={onLoad}
              onReady={onReady}
              minHeight={window.innerHeight-90}
            />
          </div>
        </div>
      </div>

    </div>:
    <Loading />
  );
};

export default Editor