import React from "react";
import { Card } from "react-bootstrap";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const CategoryList = ({ data }) => {

    const handleDelete = async (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this category!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then(async (willDelete) => {
			if (willDelete) {
				const adDocRef = doc(db, 'categories', id)
				try {
					await deleteDoc(adDocRef)
				} catch (err) {
					alert(err)
				}
			}
		});
    }

	return (
		<Card>
			<Card.Body>
				<Card.Title>Categories </Card.Title>
				<div className="w-100 mx-0 mx-md-auto row justify-content-center">
					<div id="list" className="display w-100 dataTable">
						<div id="list_wrapper" className="dataTables_wrapper">
							{data.length > 0 ?
								<table id="list" className="display w-100 dataTable">
									<thead>
										<tr role="row">
											<th >Name</th>
											<th className="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{data.map((row, i) => {
											return (
												<tr key={i + "qli"}>
													<td ><Link to={`/learn/categories/${row.id}`}  >{row.data.name}</Link></td>
													<td  className="text-center">
														<button className="btn btn-primary" onClick={() => handleDelete(row.id)}>
															Delete
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table> : <h5 className="text-muted mt-4">No data to display.</h5>}
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);

}

export default CategoryList;