/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
import Loading from "../Common/Loading";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection, deleteDoc, orderBy, query, onSnapshot, doc, getDoc, updateDoc, } from 'firebase/firestore';

import { db, storage, getFileUrl } from "../../constants/firebase";
import { Card } from "react-bootstrap";


const TemplateDetail = () => {
	const [loaded, setLoaded] = useState(true);
	const { id } = useParams();
    const [template, setTemplate] = useState("")
    const [name, setName] = useState("");

	useEffect(() => {
        setLoaded(false);
		const docRef = doc(db, "templates", id);
		getDoc(docRef).then(docSnap => {
			if (docSnap.exists()) {
				const data = docSnap.data();
                setTemplate(data.html);
                setName(data.name);
                setLoaded(true);
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
                setLoaded(true);
			}
		});
	}, []);

	return loaded ? (
        <Card>
			<Card.Body>
				<Card.Title>Template: {name}</Card.Title>
                    <div dangerouslySetInnerHTML={{ __html: template }} />
                    <div className="m-0 m-auto px-4 w-100">
                        <div>
                            <div className="w-100 text-center">
                                <Link to="/broadcasts" className="w-100 m-0 my-5 btn btn-primary">Back to List</Link>
                            </div>
                        </div>
                    </div>
			</Card.Body>
		</Card>
	) : (
		<Loading />
	);
};

export default TemplateDetail;
