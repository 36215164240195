/* eslint-disable */
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, Timestamp, addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from "../../constants/firebase";
import Loading from "../Common/Loading";

import Resources from "./Resources";

/*
Type: "Test"	
Lesson #:
Segment #:
Title: 
Start Time: 
End Time: 
Description:
Resources:

{type:"segment", lesson:"1", segment: "2", title:"join preperation", start:"0", end:"60", description:"prepare joints" resources:[]}

*/
const NewLesson = ({ show, onClose, lessonId, setShowAddLessonModal, lessons }) => {

	const [lesson, setLesson] = useState({
		number: "",
		title: '',
		description: ''
	});
	const [loaded, setLoaded] = useState(true);

	const { id } = useParams();

	const handleChange = (e) => {
		setLesson(prevState => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	}


	useEffect(async () => {

		let isMounted = true;
		setLoaded(false);

		if (lessonId) {
			//On load get lesson
			const docRef = doc(db, `courses/${id}/lessons`, lessonId);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists() && isMounted) {
				const data = docSnap.data();
				setLesson(data);

			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		}
		setTimeout(() => { setLoaded(true); }, 1000);

		return () => { isMounted = false };
	}, []);

	const handleSubmit = async () => {
		if (lesson.title) {
			setShowAddLessonModal(false);
			if(lessonId) {
				const adDocRef = doc(db, `courses/${id}/lessons`, lessonId);
				await updateDoc(adDocRef, lesson);
			} else {

				lesson['active'] = true;
				lesson['created'] = Timestamp.now();
				await addDoc(collection(db, `courses/${id}/lessons`), lesson);
			}
			
        } else {
            alert('There wasa a problem creating that course. Does one exist with that name?');
        };
	};

	return (
    <Modal
      show={show}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      centered
    >
      {loaded ? (
        <>
          <Modal.Header onHide={onClose} closeButton>
            <Modal.Title>Lesson</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col col-2">
                <label>Lesson #</label>
                <input
                  value={lesson.number}
                  type="number"
                  name="number"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>{" "}
              <div className="col col-6">
                <label>Title</label>
                <input
                  className="form-control"
                  value={lesson.title}
                  onChange={handleChange}
                  name="title"
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                className="form-control"
                value={lesson.description}
                onChange={handleChange}
                name="description"
              ></input>
            </div>
            <Button
              className="m-0 w-100 mt-3 btn-primary btn"
              onClick={handleSubmit}
            >
              {lessonId ? "Update" : "Save"}
            </Button>
          </Modal.Body>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
const Lesson = () => { };

export { NewLesson };
