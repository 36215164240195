/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SortArrow from "../../assets/icons/sort-arrow.svg";
import CourseList from "./CourseList";
import { db } from "../../constants/firebase";
import Loading from "../Common/Loading";
import { orderBy, collection, query, onSnapshot } from 'firebase/firestore';


import { Dropdown } from "react-bootstrap";

const Courses = () => {
	const [courses, setCourses] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const q = query(collection(db, 'courses'), orderBy('created', 'desc'))
		onSnapshot(q, (querySnapshot) => {
			if (isMounted) setCourses(querySnapshot.docs.map(doc => ({
				id: doc.id,
				data: doc.data()
			})));
		});

		setTimeout(() => { setLoaded(true); }, 1000);
		return () => { isMounted = false };
	}, []);

	return loaded ? (
		<Fragment>
			<div className="p-4">
				<div className="d-flex flex-wrap align-items-center pt-3 mb-4">
					<div className="mr-auto mb-2 pr-2 pb-3 ">
						<h6 className="text-black fs-16 font-w600 mb-0  d-none">
							List of Courses
						</h6>

						<span className="fs-12 text-muted  d-none">
							Showing {courses.length} Results
						</span>
					</div>
					<div className="d-flex  flex-wrap align-items-center">
						<Link
							to="/learn/courses/new"
							className="mobile-smaller btn btn-primary light btn-sm mr-2"
						>
							<i className="flaticon-381-add-1 mr-3"></i>New Course
						</Link>
					</div>
					<Dropdown className="dropdown custom-dropdown mb-0 mt-2 mt-sm-0 ml-sm-2">
						<Dropdown.Toggle
							variant=""
							className="mobile-smaller btn btn-sm border border-primary text-black "
							role="button"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<img src={SortArrow} />Newest
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
							<Dropdown.Item className="dropdown-item" to="/search-data">
								Details
							</Dropdown.Item>
							<Dropdown.Item
								className="dropdown-item text-danger"
								to="/search-data"
							>
								Close
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>{" "}
				</div>
				{courses ? <CourseList data={courses} /> : <h5 className="text-muted mt-4">No Courses to Display</h5>}
			</div>
		</Fragment>
	): (
		<Loading />
	  );
};

export default Courses;