import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";

const TemplateList = ({ data }) => {

    const handleDelete = async (id) => {
        const adDocRef = doc(db, 'templates', id)
        try {
            await deleteDoc(adDocRef)
        } catch (err) {
            alert(err)
        }
    }

	return (
		<Card>
			<Card.Body>
				<Card.Title>Templates </Card.Title>
				<div className="w-100 mx-0 mx-md-auto row justify-content-center">
					<div id="list" className="display w-100 dataTable">
						<div id="list_wrapper" className="dataTables_wrapper">
							{data && data.length > 0 ? <table id="list" className="display w-100 dataTable">
								<thead>
									<tr role="row">
										<th>Name</th>
										<th className="text-center">Created at</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody>
									{data.map((Template, i) => {
										return (
											<tr key={i + "qli"} className="text-left">
												{/* <td><Link to={"/learn/courses/" + Course.id}>{Template.data.name}</Link></td> */}
												{/* <td>{Template.data.name}</td> */}
												<td><Link to={"/broadcasts/" + Template.id}>{Template.data.name}</Link></td>
												<td className="text-center">
													{Template.data.created}
												</td>
												<td  className="text-center">
													<button className="btn btn-primary" onClick={() => handleDelete(Template.id)}>
														Delete
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table> : <h5 className="text-muted mt-4">No Templates to display.</h5>}
						</div>
					</div>
				</div>
			</Card.Body>
		</Card>
	);

}

export default TemplateList;