/* eslint-disable */
import React, { useEffect } from "react";
import { Player, ControlBar, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css"; // import css

const Video = ({ url }) => {

	useEffect(() => {
		let isMounted = true;
		return () => { isMounted = false };
	}, []);

	return (
		<>
			<Player
				playsInline
				src={url}
			>
				<BigPlayButton position="center" />
				<ControlBar autoHide={false} className="my-class" />
			</Player>
		</>
	);

};

export default Video;
