import React from "react";
import { Card } from "react-bootstrap";
import { db, getFileUrl } from "../../constants/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const SegmentList = ({ data, setSegments }) => {
    
    const handleDelete = async (label, image) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this category!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const changedRes = data.filter(function(res) { 
                        return res.label !== label && res.image !== image;
                    });
                    setSegments(changedRes);

                    let update_list = {
                        'industry_segments': changedRes,
                    } 
                    const adDocRef = doc(db, `config`, 'lists')
                    try {
                        await updateDoc(adDocRef, update_list);
                    } catch (err) {
                        alert(err)
                    }
                }
            });
    }

    const handleImageUrl = (index,image) => {
        getFileUrl(`industry_segments/${image}`, (url) => {
            const img = document.getElementById(`${index}-${image}`);
            img.setAttribute('src', url);
        });
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Industry Segments</Card.Title>
                <div className="w-100 mx-0 mx-md-auto row justify-content-center">
                    <div id="list" className="display w-100 dataTable">
                        <div id="list_wrapper" className="dataTables_wrapper">
                            {data.length > 0 ?
                                <table id="list" className="display w-100 dataTable">
                                    <thead>
                                        <tr role="row">
                                            <th >Image</th>
                                            <th >Label</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, i) => {
                                            return (
                                                <tr key={i + "seg"}>
                                                    <td><img src={handleImageUrl(i, row.image)} id={`${i}-${row.image}`} width={"80px"} alt="" /></td>
                                                    <td ><Link to={``}  >{row.label}</Link></td>
                                                    <td className="text-center">
                                                        <button className="btn btn-primary" onClick={() => handleDelete(row.label, row.image)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody> 
                                </table> : <h5 className="text-muted mt-4">No data to display.</h5>}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );

}

export default SegmentList;