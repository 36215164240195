/* eslint-disable */
import React, { Component } from "react";
/// Link
import { Link } from "react-router-dom";
import $ from "jquery";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
	constructor() { super(); }
	componentDidMount() {
		this.$el = this.el;
		this.mm = new MetisMenu(this.$el);
	}
	componentWillUnmount() {

		if (this) {
			(() => {
				this.mm("dispose");
			})();
		};

	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}


class SideBar extends Component {
	/// Open menu
	// componentDidMount() {
	//   // sidebar open/close
	//   var btn = document.querySelector(".nav-control");
	//   var aaa = document.querySelector("#main-wrapper");

	//   function toggleFunc() {
	//     aaa.classList.toggle("menu-toggle-hide");
	//     return aaa.classList.toggle("menu-toggle");

	//   }

	//   btn.addEventListener("click", toggleFunc);
	// }

	componentDidMount() {
		// sidebar open/close
		var btn = document.querySelector(".nav-control");
		var aaa = document.querySelector("#main-wrapper");
		var menuLinks = $(".mm-show li a");

		function toggleFunc() {
			aaa.classList.toggle("menu-toggle-hide");
			return aaa.classList.toggle("menu-toggle");
		}

		$(".deznav").on("click", (event) => {
			if (
				event.target.hasAttribute("href") &&
				!event.target.hasAttribute("class")
			) {
				btn.click();
			}
		});

		btn.addEventListener("click", toggleFunc);
	}
	render() {
		/// Path
		const path = window.location.pathname;

		/// Active menu
		let queries = ["", "categories", "courses", "careers", "ad-campaigns"];
		return (
			<div className="deznav">
				<PerfectScrollbar className="deznav-scroll">
					<MM className="metismenu" id="menu">
						<li className={path.includes("users") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/coming-soon"
								aria-expanded="false"
							>
								<i className="flaticon-381-user-9"></i>
								<span className="nav-text">Users</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/users/manage">Manage</Link>
								</li>
							</ul>
						</li>

						<li className={path.includes("career") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/careers/manage"
								aria-expanded="false"
							>
								<i className="flaticon-381-networking-1"></i>
								<span className="nav-text">Careers</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/careers/manage">Manage</Link>
								</li>
								<li>
									<Link to="/careers/post">New Job</Link>
								</li>
							</ul>
						</li>
						<li className={path.includes("learn") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/learn/courses"
								aria-expanded="false"
							>
								<i className="flaticon-381-idea"></i>
								<span className="nav-text">E-Learning</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/learn/courses">Courses</Link>
								</li>
								<li>
									<Link to="/learn/categories">Categories</Link>
								</li>
							</ul>
						</li>
						<li className={path.includes("ad-campaigns") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/coming-soon"
								aria-expanded="false"
							>
								<i className="flaticon-381-promotion-1"></i>
								<span className="nav-text">Ad Campaigns</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/ad-campaigns">Manage</Link>
								</li>
								<li>
									<Link to="/ad-campaigns/new">New Ad Campaign</Link>
								</li>

							</ul>
						</li>
						<li className={path.includes("broadcasts") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/coming-soon"
								aria-expanded="false"
							>
								<i className="flaticon-381-wifi-2"></i>
								<span className="nav-text">Broadcasts</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/broadcasts">Newsletter Templates</Link>
								</li>
							</ul>
						</li>
						<li className={path.includes("system") ? "mm-active" : ""}>
							<Link
								className="has-arrow ai-icon"
								to="/coming-soon"
								aria-expanded="false"
							>
								<i className="flaticon-381-settings-6"></i>
								<span className="nav-text">System</span>
							</Link>
							<ul aria-expanded="false">
								<li>
									<Link to="/system/manage">Industry Segments</Link>
								</li>
							</ul>
						</li>
					</MM>

					<div className="copyright">
						<p>
							<strong>Create</strong> © All Rights Reserved
						</p>
						<p>by Weld</p>
					</div>
				</PerfectScrollbar>
			</div>
		);
	}
}

export default SideBar;
