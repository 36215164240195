/* eslint-disable */
import "./videoUploader.css";
import ReactDropzone from 'react-dropzone'

const VideoUploader = ({ doUpload, label }) => {
  return (
      <ReactDropzone onDrop={(acceptedFiles, text) => doUpload(acceptedFiles[0], text)}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()} className={"DropZone"}>
            <input {...getInputProps()} />
            {label.length ? label : 'Click or drop your file here'}
          </div>
        )}
      </ReactDropzone>
  );
};

export default VideoUploader;
