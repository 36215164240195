/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {collection, updateDoc, doc, getDoc} from 'firebase/firestore';
import { db, storage } from "../../constants/firebase";
import { uploadBytes, ref } from "firebase/storage";

const NewSegment = () => {
	const [label, setLabel] = useState("");
	const [image, setImage] = useState("");

	const history = useHistory();
	const handleChange = (e) => {
		switch (e.target.name) {
			case "label":
				setLabel(e.target.value);
				break;
			case "image":
				setImage(e.target.files[0]);
				break;
			default:
				return;
		}
	}

    const handleCreate = async () => {
		
		//seg_image
		let seg_image = '';
		if (image) {
			seg_image = image.name;
			const storageRef = ref(storage, `industry_segments/${image.name}`);
			uploadBytes(storageRef, image).then((snapshot) => {
				console.log('Uploaded a blob or file!');
			});
		}

        if (label.length > 1) {
            const adDocRef = doc(db, 'config', "lists");
            const lists = await getDoc(doc(db, 'config', "lists"));
            let data = lists.data();

            let newEle = {
                label,
                image: seg_image
            }
            let update_arr = {
                industry_segments: []
            } 
            update_arr['industry_segments'] = [...data.industry_segments, newEle];
            await updateDoc(adDocRef, update_arr);

            history.push("/system/manage");
        } else {
            alert('There wasa a problem creating that segment. Does one exist with that label?');
        }
    }

	return (
		<Fragment>
			<div className="justify-content-center  align-items-center h-80 w-100">
				<div className="form-input-content text-center error-page">
					<h1 className="font-weight-bold mb-5">Create a Industry Segment</h1>
					<div className={"card p-5 w-lg-50 m-auto text-left"}>
						<div className="form-group">
							<label className="text-black">
								<strong>Label</strong>
							</label>
							<input
								type="text"
								className="form-control w-100"
								name="label"
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Image</strong>
							</label>
							<input
								type="file"
								onChange={handleChange}
								className="form-control w-100"
								name="image"
							/>
						</div>
						<Button
							className={"text-uppercase"}
							onClick={handleCreate}
						>
							Create
						</Button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default NewSegment;
