const Template = {
  counters: {
    u_row: 42,
    u_column: 79,
    u_content_menu: 3,
    u_content_text: 50,
    u_content_image: 37,
    u_content_button: 24,
    u_content_social: 1,
    u_content_divider: 7,
    u_content_html: 4,
    u_content_heading: 43,
  },
  body: {
    id: "79u3hYH9NY",
    rows: [
      {
        id: "3kmZkRL4My",
        cells: [1],
        columns: [
          {
            id: "GVV23lxO1O",
            contents: [
              {
                id: "guaM12i4gq",
                type: "divider",
                values: {
                  width: "100%",
                  border: {
                    borderTopWidth: "0px",
                    borderTopStyle: "solid",
                    borderTopColor: "#BBBBBB",
                  },
                  textAlign: "center",
                  containerPadding: "5px",
                  anchor: "",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_divider_6",
                    htmlClassNames: "u_content_divider",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  hideMobile: false,
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_16",
                htmlClassNames: "u_column",
              },
              border: {},
              padding: "0px",
              backgroundColor: "",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_13",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          hideMobile: false,
          noStackMobile: false,
        },
      },
      {
        id: "LKVNWqJwQP",
        cells: [1],
        columns: [
          {
            id: "WqdkEMWlgR",
            contents: [
              {
                id: "9EbLXyfFR4",
                type: "image",
                values: {
                  containerPadding: "65px 10px 65px 0px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648108910051-weld.png",
                    width: 214,
                    height: 46,
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_9",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "2z0aB1WekO",
                type: "heading",
                values: {
                  containerPadding: "54px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "31px",
                  color: "#ecf0f1",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_2",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Get the online courses<br />and learn more",
                },
              },
              {
                id: "Illp5GwmVN",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_12",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br />sed do eiusmod tempor incididunt ut labore et dolore<br />magna aliqua.</p>',
                },
              },
              {
                id: "3X-vfzJRLm",
                type: "button",
                values: {
                  containerPadding: "10px 10px 100px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#FFFFFF",
                    backgroundColor: "#e92314",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: false,
                    width: "28%",
                  },
                  textAlign: "center",
                  lineHeight: "180%",
                  padding: "10px 20px",
                  border: {},
                  borderRadius: "10px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_6",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 25.2px;">DISCOVER NOW</span>',
                  calculatedWidth: 162,
                  calculatedHeight: 45,
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_9",
                htmlClassNames: "u_column",
              },
              border: {},
              padding: "0px",
              backgroundColor: "",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "https://images.unlayer.com/projects/0/1648118120491-banner.png",
            fullWidth: false,
            repeat: false,
            center: true,
            cover: false,
            width: 600,
            height: 600,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_7",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          hideMobile: false,
          noStackMobile: false,
        },
      },
      {
        id: "SmUudlD796",
        cells: [1, 1],
        columns: [
          {
            id: "jTllBr0EFY",
            contents: [
              {
                id: "i0Ggh86RZh",
                type: "button",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "10px 20px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_7",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">Best Course</span>',
                  calculatedWidth: 117,
                  calculatedHeight: 34,
                },
              },
              {
                id: "4dUuM3Opqw",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "20px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_4",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "MIG Fundamentals",
                },
              },
              {
                id: "memBgjPQdS",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_13",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%; text-align: left;">Impress employers with your best<br />work, on the best domain for<br />welders, Duis sit urna.</p>',
                },
              },
              {
                id: "agJU4mYvEO",
                type: "button",
                values: {
                  containerPadding: "10px 10px 65px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "12px 30px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_8",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">Enroll Class</span>',
                  calculatedWidth: 135,
                  calculatedHeight: 38,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px 0px 0px 31px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_30",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "iXgHPsyei_",
            contents: [
              {
                id: "dq4FS_aMoe",
                type: "image",
                values: {
                  containerPadding: "109px 52px 10px 10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648119074000-play.png",
                    width: 60,
                    height: 61,
                  },
                  textAlign: "center",
                  altText: "Play",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.google.com/",
                      target: "_blank",
                    },
                    attrs: {
                      href: "{{href}}",
                      target: "{{target}}",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_13",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_31",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "https://images.unlayer.com/projects/0/1648122722755-enroll.png",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
            width: 600,
            height: 300,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_23",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "T5-9lyiRa1",
        cells: [1],
        columns: [
          {
            id: "50IjQJ8-K6",
            contents: [
              {
                id: "qlfaqVFQZ4",
                type: "button",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "center",
                  lineHeight: "100%",
                  padding: "7px 20px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_9",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">categories</span>',
                  calculatedWidth: 104,
                  calculatedHeight: 28,
                },
              },
              {
                id: "dE_CLbxkIS",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "20px",
                  color: "#ffffff",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_5",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Top Course Categories",
                },
              },
              {
                id: "5nJ3payeag",
                type: "text",
                values: {
                  containerPadding: "4px 10px 17px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_14",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 160%;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean<br />commo disconsectetuer adipiscing elit.Aenean.</p>',
                },
              },
            ],
            values: {
              backgroundColor: "#000000",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_32",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_24",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "u1RtuXtzMc",
        cells: [1, 1],
        columns: [
          {
            id: "Q3BJRqUtAP",
            contents: [
              {
                id: "2E05YozgBL",
                type: "heading",
                values: {
                  containerPadding: "126px 50px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "right",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_7",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Fundamentals<br />&nbsp;",
                },
              },
              {
                id: "HZsuenLWlF",
                type: "heading",
                values: {
                  containerPadding: "127px 59px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "right",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_11",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Tig Welding",
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_35",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "yKlvVDAVyB",
            contents: [
              {
                id: "vhccDNZutm",
                type: "heading",
                values: {
                  containerPadding: "127px 10px 10px 64px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_10",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Mig Welding<br />&nbsp;",
                },
              },
              {
                id: "XYSMY8OXht",
                type: "heading",
                values: {
                  containerPadding: "126px 10px 10px 78px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_12",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Welding",
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_36",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "https://images.unlayer.com/projects/0/1648175722008-Screenshot_18.png",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
            width: 600,
            height: 400,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_26",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "UPtN0v7vIG",
        cells: [1],
        columns: [
          {
            id: "0LRX1Z0pAC",
            contents: [
              {
                id: "Fyiiyuijdq",
                type: "button",
                values: {
                  containerPadding: "74px 10px 10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "center",
                  lineHeight: "100%",
                  padding: "7px 20px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_10",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">What\'s new</span>',
                  calculatedWidth: 112,
                  calculatedHeight: 28,
                },
              },
              {
                id: "6uc832Hv1c",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "20px",
                  color: "#ffffff",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_15",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "My Courses",
                },
              },
              {
                id: "0AJ2tY0XJm",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_15",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 160%;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean<br />commo disconsectetuer adipiscing elit.Aenean.</p>',
                },
              },
            ],
            values: {
              backgroundColor: "#000000",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_37",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_27",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "iQAy6sLHUg",
        cells: [1, 1, 1],
        columns: [
          {
            id: "PEuw0gPd_0",
            contents: [
              {
                id: "Ahh7gJPMH2",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648177344662-mig.png",
                    width: 148,
                    height: 87,
                    autoWidth: true,
                    maxWidth: "100%",
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_15",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "w4ol5X6OfD",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 14px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_18",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "H17mNkaq8J",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 14px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_21",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "U9-YLEu5QN",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 13px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_12",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">View Course</span>',
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px 0px 30px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderTopColor: "#ffffff",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ffffff",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderRightColor: "#ffffff",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#ffffff",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_38",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "nnNbJ2kLAP",
            contents: [
              {
                id: "3zHuY5k1Io",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648179271912-tig-one.png",
                    width: 148,
                    height: 87,
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_16",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "sLLns6l-lW",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 26px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_19",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "61BWZaxHOg",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 26px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_20",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "_ph5j4wYwW",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 26px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_11",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View Course",
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_39",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "LBWUI5c28Y",
            contents: [
              {
                id: "CPYOm-4tRc",
                type: "image",
                values: {
                  containerPadding: "10px 10px 10px 15px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648179662164-tig-two.png",
                    width: 148,
                    height: 87,
                  },
                  textAlign: "left",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_17",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "8Fwcpp2PTQ",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 18px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_20",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "kd1rCTCwk3",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 18px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_22",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers <br />with your best work.",
                },
              },
              {
                id: "gusk4MENHV",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 20px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_13",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View Course",
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_40",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#10163b",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_28",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "pFD9YhNalO",
        cells: [1, 1, 1],
        columns: [
          {
            id: "clDZvbxa4r",
            contents: [
              {
                id: "HJxSybA0WT",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648177344662-mig.png",
                    width: 148,
                    height: 87,
                    autoWidth: true,
                    maxWidth: "100%",
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_19",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "TR7GCid9km",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 14px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_21",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "uCxetPBD3b",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 14px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_23",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "dBcAom26yy",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 13px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_14",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">View Course</span>',
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px 30px 30px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderTopColor: "#ffffff",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ffffff",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderRightColor: "#ffffff",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#ffffff",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_43",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "nptswiiKd_",
            contents: [
              {
                id: "lQ2cmEC0l0",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648179271912-tig-one.png",
                    width: 148,
                    height: 87,
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_20",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "B21t_MhOut",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 26px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_22",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "EFHQBgECIS",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 26px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_24",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "Qw-HubyMBE",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 26px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_15",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View Course",
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_44",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "Bnlrr9DJ1q",
            contents: [
              {
                id: "Y5dCf0FYBc",
                type: "image",
                values: {
                  containerPadding: "10px 10px 10px 15px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648179662164-tig-two.png",
                    width: 148,
                    height: 87,
                  },
                  textAlign: "left",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_21",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "zDM4zQlPJy",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 18px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_23",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "6FvdIODVkk",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 18px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_25",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers <br />with your best work.",
                },
              },
              {
                id: "50P-d97NUF",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 20px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_16",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View Course",
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_45",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#10163b",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_30",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "gILbOqcEPK",
        cells: [42, 58],
        columns: [
          {
            id: "rY63thd01l",
            contents: [
              {
                id: "4RRjpcLQm8",
                type: "button",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#ee6e6a",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_17",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">Best Part of Weld</span>',
                  calculatedWidth: 123,
                  calculatedHeight: 28,
                },
              },
              {
                id: "XKdYO_jToW",
                type: "heading",
                values: {
                  containerPadding: "15px 10px 10px 11px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "18px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_26",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Certificate for Completion",
                },
              },
              {
                id: "1As_hZt3tz",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_24",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Impress employers with your best work, on the best domain for welders, Duis sit urna.</p>',
                },
              },
              {
                id: "3n8v45gMR-",
                type: "button",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "9px 22px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_18",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Enroll Class",
                  calculatedWidth: 119,
                  calculatedHeight: 32,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "48px 0px 0px 27px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_41",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "QNl0xYI6fF",
            contents: [
              {
                id: "6KMg-Txk5S",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648179908723-certificate.png",
                    width: 286,
                    height: 249,
                  },
                  textAlign: "left",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_18",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_42",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_29",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "Kh_2B9tiDK",
        cells: [1],
        columns: [
          {
            id: "AqaGW72CE9",
            contents: [
              {
                id: "jhokBGN6dX",
                type: "button",
                values: {
                  containerPadding: "47px 10px 10px",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#ee6e6a",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "center",
                  lineHeight: "100%",
                  padding: "7px 15px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_19",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">New Opportunities</span>',
                  calculatedWidth: 146,
                  calculatedHeight: 28,
                },
              },
              {
                id: "CUeL-st_-Z",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "20px",
                  color: "#ffffff",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_28",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Job Board",
                },
              },
              {
                id: "LQQ5tw6GQo",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_25",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean<br />commo disconsectetuer adipiscing elit.Aenean.</p>',
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_15",
                htmlClassNames: "u_column",
              },
              border: {},
              padding: "0px",
              backgroundColor: "#000000",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_12",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          hideMobile: false,
          noStackMobile: false,
        },
      },
      {
        id: "H6x3zOQxI4",
        cells: [17.17, 32.34, 12.32, 38.17],
        columns: [
          {
            id: "IAb5fTsAuz",
            contents: [
              {
                id: "Ve_KUXj65Y",
                type: "image",
                values: {
                  containerPadding: "50px 10px 10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648183959263-job-icon-1.png",
                    width: 50,
                    height: 51,
                  },
                  textAlign: "right",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_22",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "8px 0px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_47",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "3NIUx1bvMn",
            contents: [
              {
                id: "Bv_xh_-STL",
                type: "heading",
                values: {
                  containerPadding: "45px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "130%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_29",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Fundamentals Instructor",
                },
              },
              {
                id: "En15r2XcZX",
                type: "text",
                values: {
                  containerPadding: "0px 10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_26",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Weld . Chicago, NY</p>',
                },
              },
              {
                id: "LwaXTxPFgC",
                type: "text",
                values: {
                  containerPadding: "10px 10px 0px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_27",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">$2500/mo</p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_48",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "9zUnHZnjyC",
            contents: [
              {
                id: "eKnxWHX1Jk",
                type: "image",
                values: {
                  containerPadding: "57px 10px 10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648183959263-job-icon-1.png",
                    width: 50,
                    height: 51,
                  },
                  textAlign: "right",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_23",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_49",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "DX7ktM3Iry",
            contents: [
              {
                id: "ACSvHb7wnY",
                type: "heading",
                values: {
                  containerPadding: "45px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "130%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_30",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Fundamentals Instructor",
                },
              },
              {
                id: "AmQFZGHDLL",
                type: "text",
                values: {
                  containerPadding: "0px 10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_28",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Weld . Chicago, NY</p>',
                },
              },
              {
                id: "EWdpcPFxfa",
                type: "text",
                values: {
                  containerPadding: "10px 10px 0px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_29",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">$2500/mo</p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_50",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          _meta: {
            htmlID: "u_row_32",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "wBOBxKO4O9",
        cells: [17.17, 32.34, 12.32, 38.17],
        columns: [
          {
            id: "e0Hk4TEC8M",
            contents: [
              {
                id: "YYFp54GWl2",
                type: "image",
                values: {
                  containerPadding: "50px 10px 10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648183959263-job-icon-1.png",
                    width: 50,
                    height: 51,
                  },
                  textAlign: "right",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_36",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "8px 0px 0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_76",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "RjCyTTbisA",
            contents: [
              {
                id: "2sWmIclF8X",
                type: "heading",
                values: {
                  containerPadding: "45px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "130%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_42",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Fundamentals Instructor",
                },
              },
              {
                id: "9vtl-ixT2y",
                type: "text",
                values: {
                  containerPadding: "0px 10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_47",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Weld . Chicago, NY</p>',
                },
              },
              {
                id: "ytMhpHc7Rm",
                type: "text",
                values: {
                  containerPadding: "10px 10px 0px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_48",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">$2500/mo</p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_77",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "_yxImHwP6e",
            contents: [
              {
                id: "nigxUViAPT",
                type: "image",
                values: {
                  containerPadding: "57px 10px 10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648183959263-job-icon-1.png",
                    width: 50,
                    height: 51,
                  },
                  textAlign: "right",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_37",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_78",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "xB8SDs14VM",
            contents: [
              {
                id: "TT0CfcnYVi",
                type: "heading",
                values: {
                  containerPadding: "45px 10px 10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "130%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_43",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Fundamentals Instructor",
                },
              },
              {
                id: "bzGFnqwZnP",
                type: "text",
                values: {
                  containerPadding: "0px 10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_49",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Weld . Chicago, NY</p>',
                },
              },
              {
                id: "ddGKf3PJth",
                type: "text",
                values: {
                  containerPadding: "10px 10px 0px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_50",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">$2500/mo</p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_79",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          _meta: {
            htmlID: "u_row_42",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "ubMn7aoK9d",
        cells: [1],
        columns: [
          {
            id: "7ty5XpumdL",
            contents: [
              {
                id: "fqheH-E-Dd",
                type: "button",
                values: {
                  containerPadding: "47px 10px 10px",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#000000",
                    backgroundColor: "#ee6e6a",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "center",
                  lineHeight: "100%",
                  padding: "7px 15px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_20",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">Latest News</span>',
                  calculatedWidth: 108,
                  calculatedHeight: 28,
                },
              },
              {
                id: "V1n143D_z3",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "20px",
                  color: "#ffffff",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_33",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Global Post",
                },
              },
              {
                id: "XN11u_106q",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_34",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean<br />commo disconsectetuer adipiscing elit.Aenean.</p>',
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_59",
                htmlClassNames: "u_column",
              },
              border: {},
              padding: "0px",
              backgroundColor: "#000000",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_35",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
          hideMobile: false,
          noStackMobile: false,
        },
      },
      {
        id: "mT6BaBbcG5",
        cells: [1, 1],
        columns: [
          {
            id: "EWO_o0yjD3",
            contents: [
              {
                id: "5xMySu0wlf",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648185218072-post-1.png",
                    width: 234,
                    height: 116,
                    autoWidth: true,
                    maxWidth: "100%",
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_26",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "p2gquSxfqU",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 32px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_35",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "FVr_AL6YK6",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 32px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_34",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "twQruWl1h5",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 29px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_21",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span style="font-size: 14px; line-height: 14px;">View Course</span>',
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px",
              border: {
                borderTopWidth: "4px",
                borderTopStyle: "solid",
                borderTopColor: "#ffffff",
                borderLeftWidth: "4px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ffffff",
                borderRightWidth: "2px",
                borderRightStyle: "solid",
                borderRightColor: "#ffffff",
                borderBottomWidth: "4px",
                borderBottomStyle: "solid",
                borderBottomColor: "#ffffff",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_60",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "6teHy8k4mk",
            contents: [
              {
                id: "1scXthCn4e",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648185236993-post-2.png",
                    width: 234,
                    height: 116,
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_27",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "4_63ECeNLF",
                type: "text",
                values: {
                  containerPadding: "10px 10px 10px 32px",
                  anchor: "",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "100%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_36",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">MIG Fundamentals</p>',
                },
              },
              {
                id: "D5iD0djhBy",
                type: "heading",
                values: {
                  containerPadding: "1px 10px 10px 31px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_35",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Impress employers with your best work.",
                },
              },
              {
                id: "p3ezodPpC9",
                type: "button",
                values: {
                  containerPadding: "10px 10px 10px 30px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "7px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_22",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View Course",
                  calculatedWidth: 93,
                  calculatedHeight: 28,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "30px 0px",
              border: {
                borderTopWidth: "4px",
                borderTopStyle: "solid",
                borderTopColor: "#ecf0f1",
                borderLeftWidth: "2px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ecf0f1",
                borderRightWidth: "4px",
                borderRightStyle: "solid",
                borderRightColor: "#ecf0f1",
                borderBottomWidth: "4px",
                borderBottomStyle: "solid",
                borderBottomColor: "#ecf0f1",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_61",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#10163b",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_36",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "38VkILLk2w",
        cells: [56.66, 43.34],
        columns: [
          {
            id: "etusXY_S24",
            contents: [
              {
                id: "RNjxrePNQu",
                type: "heading",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textAlign: "left",
                  lineHeight: "130%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_37",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Go Big on Tig!",
                },
              },
              {
                id: "oHAANwXlFk",
                type: "text",
                values: {
                  containerPadding: "10px 10px 0px",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_39",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Impress employers with your best work,<br />on the best domain for welders.</p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "40px 0px 40px 40px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_63",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "AFdy71lsKE",
            contents: [
              {
                id: "N6OFFGbXJl",
                type: "button",
                values: {
                  containerPadding: "10px",
                  href: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  buttonColors: {
                    color: "#ffffff",
                    backgroundColor: "#f04437",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "left",
                  lineHeight: "100%",
                  padding: "14px 38px",
                  border: {},
                  borderRadius: "8px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_24",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "View course",
                  calculatedWidth: 152,
                  calculatedHeight: 42,
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "65px 0px 0px 44px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_64",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          _meta: {
            htmlID: "u_row_37",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "qooIUuQaLA",
        cells: [1, 1],
        columns: [
          {
            id: "qI2zu9Qk6J",
            contents: [
              {
                id: "mvdgGql53j",
                type: "image",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  src: {
                    url: "https://images.unlayer.com/projects/0/1648186706494-footer-logo.png",
                    width: 113,
                    height: 25,
                  },
                  textAlign: "left",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_31",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "7JpQPkuDQR",
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  color: "#95a5a6",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_42",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Sed ut perspiciatis unde omnis iste<br />natus error sit voluptatem accusantium<br />doloremque laudantium, totam rem<br />aperiam,</p>',
                },
              },
            ],
            values: {
              backgroundColor: "#000000",
              padding: "20px 0px 0px 39px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_69",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "-c10JSwCEL",
            contents: [
              {
                id: "dO2mmNqCk9",
                type: "html",
                values: {
                  html: '\n<div style="display: flex; justify-content: start; align-items: start; padding-left: 0; margin: 0;">\n  <img src="https://images.unlayer.com/projects/0/1648187016638-location.png" alt="">\n<div style="color:#95A5A6;margin-left:10px"><p >Company Name 1234 Street<br>Stage Post 1234</p></div>\n</div>',
                  displayCondition: null,
                  containerPadding: "10px",
                  anchor: "",
                  _meta: {
                    htmlID: "u_content_html_2",
                    htmlClassNames: "u_content_html",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "D0zA0L11NW",
                type: "html",
                values: {
                  html: '\n<div style="display: flex; justify-content: start; align-items: center; padding-left: 0; margin: 0;">\n  <img src="https://images.unlayer.com/projects/0/1648187881931-Phone.png" alt="">\n<div style="color:#95A5A6;margin-left:10px"><p >001-1234-5678</p></div>\n</div>',
                  displayCondition: null,
                  containerPadding: "10px",
                  _meta: {
                    htmlID: "u_content_html_4",
                    htmlClassNames: "u_content_html",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "VxGMRg4CNY",
                type: "html",
                values: {
                  html: '\n<div style="display: flex; justify-content: start; align-items: center; padding-left: 0; margin: 0;">\n  <img src="https://images.unlayer.com/projects/0/1648187938514-sms.png" alt="">\n<div style="color:#95A5A6;margin-left:10px"><p >Info@weld.com</p></div>\n</div>',
                  displayCondition: null,
                  containerPadding: "10px",
                  _meta: {
                    htmlID: "u_content_html_3",
                    htmlClassNames: "u_content_html",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "#000000",
              padding: "20px 0px 0px 32px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_70",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          _meta: {
            htmlID: "u_row_39",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "ZgpW3Uk1yd",
        cells: [1],
        columns: [
          {
            id: "Jt09eoADd6",
            contents: [
              {
                id: "qoXoW--hNs",
                type: "divider",
                values: {
                  width: "100%",
                  border: {
                    borderTopWidth: "2px",
                    borderTopStyle: "solid",
                    borderTopColor: "#156ba1",
                  },
                  textAlign: "center",
                  containerPadding: "20px 45px",
                  anchor: "",
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_divider_7",
                    htmlClassNames: "u_content_divider",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                id: "8VjVqecLff",
                type: "heading",
                values: {
                  containerPadding: "0px 10px 29px 31px",
                  headingType: "h4",
                  fontFamily: {
                    label: "Arial",
                    value: "arial,helvetica,sans-serif",
                  },
                  fontSize: "13px",
                  color: "#95a5a6",
                  textAlign: "center",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_39",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Copyright &copy; 2022 Weld. All Rights Reserved.",
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_71",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          _meta: {
            htmlID: "u_row_40",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: "center",
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentVerticalAlign: "center",
      contentWidth: "600px",
      fontFamily: {
        label: "Montserrat",
        value: "'Montserrat',sans-serif",
        url: "https://fonts.googleapis.com/css?family=Montserrat:400,700",
        defaultFont: true,
      },
      textColor: "#000000",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: true,
      },
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_position: "top-right",
      popupCloseButton_backgroundColor: "#DDDDDD",
      popupCloseButton_iconColor: "#000000",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_margin: "0px",
      popupCloseButton_action: {
        name: "close_popup",
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: "#E5E5E5",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: {
        htmlID: "u_body",
        htmlClassNames: "u_body",
      },
    },
  },
  schemaVersion: 8,
};

export default Template
