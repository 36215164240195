import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import swal from "sweetalert";

const style = {
    width: '40%'
};
const JobList = ({ data }) => {
    
    const handleDelete = async (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this job!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  })
		  .then(async (willDelete) => {
			if (willDelete) {
                const jobDocRef = doc(db, 'jobs', id)
                try {
                    await deleteDoc(jobDocRef)
                } catch (err) {
                    alert(err)
                }
			}
		});
    }

    return (
        <Card >
            <Card.Body>
                <Card.Title>Jobs </Card.Title>
                <div className="w-100 mx-0 mx-md-auto row justify-content-center">
                    <div id="list" className="display w-100 dataTable">
                        <div id="list_wrapper" className="dataTables_wrapper">
                            {data.length > 0 ?
                                <table id="list" className="display w-100 dataTable">
                                    <thead>
                                        <tr role="row">
                                            <th style={style}>Title</th>
                                            <th>Company Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((job, i) => {
                                            const data = job.data
                                            return (
                                                <tr key={i + "qli"}>
                                                    <td><Link to={`/careers/${job.id}`}  >{data.title} </Link></td>
                                                    <td>{data.companyname}</td>
                                                    <td >
                                                        <button className="btn btn-primary" onClick={() => handleDelete(job.id)}>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> : <h5 className="text-muted mt-4">No data to display.</h5>}
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );

}

export default JobList;