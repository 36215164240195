/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TemplateList from "./TemplateList";
import { db } from "../../constants/firebase";
import Loading from "../Common/Loading";
import { orderBy, collection, query, onSnapshot } from 'firebase/firestore';

const EmailEditor = () => {

	const [Templates, setTemplates] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const q = query(collection(db, 'templates'), orderBy('created', 'desc'))
		onSnapshot(q, (querySnapshot) => {
			if (isMounted) setTemplates(querySnapshot.docs.map(doc => ({
				id: doc.id,
				data: doc.data()
			})));
		});

		setTimeout(() => { setLoaded(true); }, 1000);
		return () => { isMounted = false };
	}, []);

	return loaded ? (
		<Fragment>
			<div className="p-4">
				<div className="d-flex flex-wrap align-items-center pt-3 mb-4 justify-content-between">
					<div className="align-items-center">
						<h6 className="text-black fs-16 font-w600 mb-0">
							List of Templates
						</h6>
						<span className="fs-12">
							Showing {Templates.length} Results
						</span>
					</div>
					<div className="d-flex  flex-wrap align-items-center">
						<Link
							to="/broadcasts/new"
							className="mobile-smaller btn btn-primary light btn-sm mr-2"
						>
							<i className="flaticon-381-add-1 mr-3"></i>New Email Template
						</Link>
					</div>
				</div>
				{Templates ? <TemplateList data={Templates} /> : <h5 className="text-muted mt-4">No Templates to Display</h5>}
			</div>
		</Fragment>
	): (
		<Loading />
	  );
};

export default EmailEditor;