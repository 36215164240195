/* eslint-disable */
import { Modal, ListGroup, Button } from "react-bootstrap";
import { useState } from "react";
import Loading from "../Common/Loading";
import { db, storage, getFileUrl } from "../../constants/firebase";
import {collection, addDoc, Timestamp, doc, deleteDoc, updateDoc, FieldValue } from 'firebase/firestore';
import { uploadBytes, ref } from "firebase/storage";

const Resources = ({ segment, setSegment, course_id, segmentId, setShowAddSegmentModal }) => {

	const [addModal, setAddModal] = useState(false);
	const [resources, setResources] = useState([]);
	const [loaded, setLoaded] = useState(true);

	const ResourceList = () => {

		const trashResourceFB = async (id) => {
			const changedRes = segment.resources.filter(function(res) { 
				return res.id !== id
			});
			segment['resources'] = changedRes;
			setSegment(segment);
			const adDocRef = doc(db, `courses/${course_id}/segments`, segmentId)
			try {
				await updateDoc(adDocRef,{resources: changedRes});
			} catch (err) {
				alert(err)
			}
		}

		return (
			<div>
				<ListGroup>
					{segment.resources.map((resource) => {
						let url_ = "";
						if(resource.type=="file") {
							getFileUrl(resource.filename, (url) => {
								url_ = url;
							});
						} else {
							url_ = resource.link;
						}
						return (
							<ListGroup.Item key={resource.id}>
								<i
									className={
										"text-muted mr-3 flaticon-381-" +
										(resource.type === "file" ? "archive" : "networking-1")
									}
								></i>
								<small role="button" onClick={()=> window.open(url_, "_blank")}>{resource.label}</small>
								<small className="pull-right ">
									<i role="button" className="flaticon-381-trash-1 indigo text-info" onClick={() => trashResourceFB(resource.id)}></i>
								</small>
							</ListGroup.Item>
						);
					})}
				</ListGroup>
			</div>
		);
	}

	const ResourceAdd = ({ show, onClose}) => {

		const [resource, setResource] = useState({id: Math.floor(Date.now()/1000), label: "", type: "file", file: null, filename: null});

		const handleChange = (e) => {

			switch (e.target.name) {
				case "label":
					resource.label = e.target.value;
					setResource({ ...resource });
					break;
				case "resource":
					resource.type = e.target.value;
					setResource({ ...resource });
					break;
				case "link":
					resource.link = e.target.value;
					setResource({ ...resource });
					break;
				case "file":
					resource.file = e.target.files[0];
					setResource({ ...resource });
					break;
				default:
					break;
			}
		}

		const onSave = async () => {
			if(resource.label && resource.file || resource.link) {
				setLoaded(false);
				if(resource.file) {
					const unique_file_name = Math.floor(Date.now()/1000);
					let filename = unique_file_name+'.'+ resource.file.name.split('.').pop();
					const storageRef = ref(storage, filename);
					await uploadBytes(storageRef, resource.file);
					resource.filename = filename;
					resource.file = null;
				}
				segment['resources'] = [...segment.resources, resource];
				if(segmentId) {
					const adDocRef = doc(db, `courses/${course_id}/segments`, segmentId);
					await updateDoc(adDocRef, {resources: segment['resources']});
				}
				setLoaded(true);
				setAddModal(false);
				setSegment(segment);

			} else {
				alert('There wasa a problem creating that resource. Does one exist with that label and file?');
			}
		}

		return (
			<Modal
				show={show}
				dialogClassName="modal-90w"
				aria-labelledby="example-custom-modal-styling-title"
				onClose={onClose}
				size="lg"
				centered
			>
				{loaded ?
					<>
						<Modal.Header
							onHide={onClose}
							closeButton
						>
							<Modal.Title>Add Resource</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group row">
								<div className="col col-2">
									<label>Label</label>
								</div>

								<div className="col col-10">
									<input
										type="value"
										onChange={handleChange}
										className="form-control"
										placeholder="Label"
										name="label"
									></input>
								</div>
							</div>

							<div className="form-group row">
								<div className="col col-2">
									<label>Type</label>
								</div>

								<div className="col col-10">
									<select
										onChange={handleChange}
										name="resource"
										className="w-100 form-control"
									>
										<option value="file">File - Course Material</option>
										<option value="file">File - Course Transcript</option>
										<option value="link">Link - Resource</option>
									</select>
								</div>
							</div>

							<div className="form-group row">
								<div className="col col-2">
									<label className="text-capitalize">{resource.type}</label>
								</div>
								<div className="col col-10">
									{resource.type=='link' ? <input type="text" className="form-control" placeholder="Link" name="link" onChange={handleChange} /> : <input type="file" name="file" onChange={handleChange} />} 
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer >
							<Button onClick={onClose} className="m-0 btn-outline-primary">
								Close
							</Button>
							<Button variant="primary" onClick={onSave}>
								Save changes
							</Button>
						</Modal.Footer>
					</> : <Loading />
				}
			</Modal>
		);

	}

	const handleSubmit = async () => {
		if(!segment.lesson_id) return alert('There was a problem creating that segment. Please select lesson first');
		if (segment.title && segment.description) {
			setShowAddSegmentModal(false);
			if(segmentId) {
				const adDocRef = doc(db, `courses/${course_id}/segments`, segmentId);
				await updateDoc(adDocRef, segment);
			} else {
				segment['active'] = true;
				segment['created'] = Timestamp.now();
				await addDoc(collection(db, `courses/${course_id}/segments`), segment);
			}
        } else {
            alert('There was a problem creating that segment. Does one exist with that title and description?');
        };
	};

	return (
		<>
			{resources ? <ResourceList /> : ""}

			<ResourceAdd 
				show={addModal} 
				onClose={() => { setAddModal(false) }} 
			/>

			<div className="form-group text-center mt-2">
				<button
					className="btn btn-sm btn-primary"
					onClick={() => { setAddModal(!addModal); }}
				>
					Add Resource
				</button>
			</div>
			<Button className="m-0 w-100 mt-3 btn-primary btn"  onClick={handleSubmit}>{segmentId ? 'Update' : 'Save'}</Button>
		</>
	);

}
export default Resources;