/* eslint-disable */
import './Courses.css';
import { useState, useEffect, Fragment } from "react";
// import {
// 	CategoryData, InstructorData,
// 	CourseData
// } from "../../constants/data";
import Loading from "../Common/Loading";
import { useHistory, useParams } from "react-router-dom";
import { Card, ListGroup } from "react-bootstrap";

import { UncontrolledCollapse } from "reactstrap";
import { collection, deleteDoc, orderBy, query, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { uploadBytes, ref } from "firebase/storage";
import { db, storage, getFileUrl } from "../../constants/firebase";
import VideoUploader from '../Common/VideoUploader';
import Instructors from "./Instructors";
import Video from "../Common/VideoPlayer";
import CourseResources from "./CourseResources";
import { NewLessonSegment } from "./CourseLessonSegment"
import { NewLesson } from "./CourseLesson"
import Categories from "./Categories";

const CourseDetail = () => {
	const [activeDefault, setActiveDefault] = useState(0);
	const [coverImage, setCoverImage] = useState(null);
	const [url, setURL] = useState("");
	const [imageName, setImageName] = useState("");
	const [showAddSegmentModal, setShowAddSegmentModal] = useState(false);
	const [showAddLessonModal, setShowAddLessonModal] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const [categories, setCategories] = useState([]);
	const [lessons, setLessons] = useState([]);
	const [segments, setSegments] = useState([]);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [instructor, setInstructor] = useState([]);
	const [instructorData, setInstructorData] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [label, setLabel] = useState("");
	const [videoFile, setVideoFile] = useState(null);
	const [videoURL, setVideoURL] = useState(null);
	const [pricing, setPricing] = useState("");
	const [lessonId, setLessonId] = useState("");
	const [segmentId, setSegmentId] = useState("");

	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {

		let isMounted = true;
		if (isMounted) setLoaded(false);
		//On load get categories
		const categories_query = query(collection(db, 'categories'));
		onSnapshot(categories_query, (querySnapshot) => {
			if (isMounted) setCategories(querySnapshot.docs.map(doc => ({
				id: doc.id,
				name: doc.data().name,
				data: doc.data()
			})))
		});
		const users_query = query(collection(db, 'users'));
		onSnapshot(users_query, (querySnapshot) => {
			if (isMounted) setInstructorData(querySnapshot.docs.map(doc => ({
				id: doc.id,
				name: doc.data().fullname,
				avatarURL: doc.data().avatarURL ? doc.data().avatarURL : '',
			})).filter(doc => doc.name !== undefined))
		});

		//On load get lessons
		const lessons_query = query(collection(db, `courses/${id}/lessons`), orderBy('number'));
		onSnapshot(lessons_query, (querySnapshot) => {
			if (isMounted) setLessons(querySnapshot.docs.map(doc => ({
				id: doc.id,
				data: doc.data()
			})))
		});

		//On load get segments
		const segments_query = query(collection(db, `courses/${id}/segments`), orderBy('number'));
		onSnapshot(segments_query, (querySnapshot) => {
			if (isMounted) setSegments(querySnapshot.docs.map(doc => ({
				id: doc.id,
				data: doc.data()
			})))
		});

		const docRef = doc(db, "courses", id);
		getDoc(docRef).then(docSnap => {
			if (docSnap.exists() && isMounted) {
				const data = docSnap.data();
				setName(data.name);
				setDescription(data.description);
				setSelectedCategories(data.categories);
				setPricing(data.pricing);
				setInstructor(data.instructor.length ? data.instructor : []);
				getFileUrl(data.video, (url) => {
					setVideoURL(url)
				});
				setImageName(data.coverImage);
				getFileUrl(data.coverImage, (url) => {
					setURL(url)
				});
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		});


		setTimeout(() => { setLoaded(true); }, 1000);

		return () => { isMounted = false };
	}, []);

	const multiInstructorSelector = (values) => {
		setInstructor(values);
	};

	const catHandleChangeMultiBox = (values) => {
		setSelectedCategories(values);
	};

	const doUpload = (file) => {
		setVideoFile(file);
		setLabel(file.name);
	};

	const handleChange = (e) => {
		switch (e.target.name) {
			case "name":
				setName(e.target.value);
				break;
			case "coverImage":
				setCoverImage(e.target.files[0]);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			default:
				return;
		}
	};

	/* function to update document in firestore */
	const handleUploadClick = async () => {
		const courseDocRef = doc(db, 'courses', id)
		try {
			setLoaded(false);
			let video = '';
			if (videoFile) {
				video = videoFile.name;
				const storageRef = ref(storage, videoFile.name);
				await uploadBytes(storageRef, videoFile);
				await getFileUrl(video, (url) => {
					setVideoURL(url)
				});
				setLoaded(true);
				setLabel("");
			}
			await updateDoc(courseDocRef, {
				video
			});

		} catch (err) {
			alert(err)
		}
	}

	/* function to update document in firestore */
	const handleCheckedChange = async () => {
		const courseDocRef = doc(db, 'courses', id)
		try {

			if (name.length >= 1 && selectedCategories.length >= 1) {

				let cover_image = '';
				if (coverImage) {
					cover_image = coverImage.name;
					const storageRef = ref(storage, coverImage.name);
					await uploadBytes(storageRef, coverImage)
				}
				await updateDoc(courseDocRef, {
					name,
					coverImage: cover_image ? cover_image : imageName,
					description,
					categories: selectedCategories,
					pricing,
					instructor
				});
				history.push("/learn/courses");
			} else {
				alert('There wasa a problem updating that course. Does one exist with that name or category?');
			};

		} catch (err) {
			alert(err)
		}
	}

	/***** 
	 * 
	 * USE FREE & PAID on This to FLAG THEM *** 
	 * 
	 * New Section Modal, 
	 * 
	 * Start Timestamps.
	 * File Upload
	 * Files
	 * Resources <--- Per section. 
	 *  make a whatsapp group for weld.com 
	 * 
	 * Each Lesson --  Questions & Comments
	 * 
	 * Comments Open -> 
	 * 
	 * Elearning
	 * 
	 * *****/
	const trashLesson = async (lessonId) => {

		const adDocRef = doc(db, `courses/${id}/lessons`, lessonId)
		try {
			await deleteDoc(adDocRef);
		} catch (err) {
			alert(err)
		}
	};
	const trashSegment = async (segmentId) => {

		const adDocRef = doc(db, `courses/${id}/segments`, segmentId)
		try {
			await deleteDoc(adDocRef);
		} catch (err) {
			alert(err)
		}
	};

	return loaded ? (
    <div className="m-0 m-auto px-4 w-100">
      {showAddLessonModal ? (
        <NewLesson
          lessonId={lessonId}
          lessons={lessons}
          show={showAddLessonModal}
          setShowAddLessonModal={setShowAddLessonModal}
          onClose={() => {
            setShowAddLessonModal(false);
          }}
        />
      ) : (
        ""
      )}
      {showAddSegmentModal ? (
        <NewLessonSegment
          lessons={lessons}
          segmentId={segmentId}
          show={showAddSegmentModal}
          setShowAddSegmentModal={setShowAddSegmentModal}
          onClose={() => {
            setShowAddSegmentModal(false);
          }}
        />
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-12">
          <Card>
            <Card.Body>
              <h4 className="mb-4">Course Material</h4>

              {videoURL ? <Video url={videoURL} /> : ""}
              <div className="w-100 form-group mb-0">
                <div className="row mx-0 py-3 border-bottom" id="lesson">
                  <div className="col col-10 px-0 mx-0">
                    <label className="text-primary m-0 p-0">Lessons</label>
                  </div>
                  <div className="col col-2 text-right">
                    <div>
                      <i className="flaticon-381-add-1"></i>
                    </div>
                  </div>
                </div>
                <UncontrolledCollapse className="w-100" toggler="lesson">
                  <div className=" w-100">
                    {lessons.length === 0 ? (
                      <div className="form-group mt-4">No lesson found.</div>
                    ) : (
                      ""
                    )}
                    {lessons.map((row, index) => {
                      return (
                        <div className="p-4 row" key={index}>
                          <div className="col col-8 text-left">
                            <span
                              role="button"
                              onClick={() => {
                                setLessonId(row.id);
                                setShowAddLessonModal(true);
                              }}
                            >
                              {row.data.number}. {row.data.title}
                            </span>
                          </div>
                          <div className="col col-3 text-right"></div>
                          <div className="col col-1 text-right">
                            <i
                              role="button"
                              className="text-light flaticon-381-trash-1 indigo block border border-dark p-2 bg-dark"
                              onClick={() => trashLesson(row.id)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                    <div className="form-group text-center">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setLessonId("");
                          setShowAddLessonModal(true);
                        }}
                      >
                        Add Lesson
                      </button>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
              <div className="w-100 form-group mb-0">
                <div className="row mx-0 py-3 border-bottom" id="segments">
                  <div className="col col-10 px-0 mx-0">
                    <label className="text-primary m-0 p-0">Segments</label>
                  </div>
                  <div className="col col-2 text-right">
                    <div>
                      <i className="flaticon-381-add-1"></i>
                    </div>
                  </div>
                </div>
                <UncontrolledCollapse className="w-100" toggler="segments">
                  <div className=" w-100">
                    {segments.length === 0 ? (
                      <div className="form-group mt-4">No segment found.</div>
                    ) : (
                      ""
                    )}

                    <ListGroup as="ul">
                      {lessons.map((list, i) => (
                        <Fragment key={i}>
                          {segments.filter(
                            (row, index) => row.data.lesson_id === list.id
                          ).length == 0 ? (
                            ""
                          ) : (
                            <>
                              <ListGroup.Item as="li" active>
                                {list.data.number}. {list.data.title}
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {segments.map((row, index) => {
                                  return row.data.lesson_id === list.id ? (
                                    <div className="p-4 row" key={index}>
                                      <div className="col col-8 text-left">
                                        <span
                                          role="button"
                                          onClick={() => {
                                            setSegmentId(row.id);
                                            setShowAddSegmentModal(true);
                                          }}
                                        >
                                          {row.data.number}. {row.data.title}
                                        </span>
                                      </div>
                                      <div className="col col-3 text-right">
                                        <i className="flaticon-381-alarm-clock mr-2"></i>
                                        {row.data.minutes
                                          ? row.data.minutes
                                          : "00"}
                                        :
                                        {row.data.seconds
                                          ? row.data.seconds
                                          : "00"}
                                      </div>
                                      <div className="col col-1 text-right">
                                        <i
                                          role="button"
                                          className="text-light flaticon-381-trash-1 indigo block border border-dark p-2 bg-dark"
                                          onClick={() => trashSegment(row.id)}
                                        ></i>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  );
                                })}
                              </ListGroup.Item>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </ListGroup>

                    <div className="form-group text-center mt-3">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setSegmentId("");
                          setShowAddSegmentModal(true);
                        }}
                      >
                        Add Segment
                      </button>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
              <div className="w-100 form-group mb-0">
                <div className="row mx-0 py-3 border-bottom" id="videoFile">
                  <div className="col col-10 px-0 mx-0">
                    <label className="text-primary m-0 p-0">Video File</label>
                  </div>
                  <div className="col col-2 text-right">
                    <div>
                      <i className="flaticon-381-add-1"></i>
                    </div>
                  </div>
                </div>
                <UncontrolledCollapse className="w-100" toggler="videoFile">
                  <div className="text-center w-100">
                    <div className="p-4">
                      <VideoUploader doUpload={doUpload} label={label} />
                      <button
                        onClick={handleUploadClick}
                        className="btn btn-primary pull-right mt-4"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12">
          <Card>
            <Card.Body>
              <h4>Course Details</h4>
              <div className="w-100 form-group mb-0 ">
                <div className="pt-4 form-group row">
                  <label className="col-4 col-form-label">Name</label>
                  <div className="col">
                    {" "}
                    <input
                      className="form-control w-100 fs-12"
                      defaultValue={name}
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="pt-4 form-group row">
                  <label className="col-4 col-form-label">Cover Image</label>
                  <div className="col">
                    {url ? (
                      <div className="avatar-container">
                        <img src={url} alt="" width={"auto"} height={"80px"} />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      onChange={handleChange}
                      className="form-control w-100"
                      name="coverImage"
                    />
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Category</label>
                  <div className="col">
                    {" "}
                    <Categories
                      data={categories}
                      categories={selectedCategories}
                      catHandleChangeMultiBox={catHandleChangeMultiBox}
                    />
                  </div>
                </div>

                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Description</label>
                  <div className="col">
                    <textarea
                      className="p-2 mt-2 form-control w-100 fs-12"
                      style={{ height: "300px" }}
                      defaultValue={description}
                      name="description"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Pricing</label>
                  <div className="col">
                    <div className="bd-example">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pricing"
                          id="free"
                          value="free"
                          checked={pricing === "free"}
                          onChange={(e) => setPricing(e.currentTarget.value)}
                        />
                        <label
                          className="form-check-label  m-0 p-0"
                          htmlFor="free"
                        >
                          Basic
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pricing"
                          id="paid"
                          value="paid"
                          checked={pricing === "paid"}
                          onChange={(e) => setPricing(e.currentTarget.value)}
                        />
                        <label
                          className="form-check-label m-0 p-0"
                          htmlFor="paid"
                        >
                          Premium
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 form-group mb-0 ">
                <div
                  className="row mx-0 py-3 border-bottom"
                  id="courseInstructor"
                >
                  <div className="col col-10 px-0 mx-0">
                    <label className="text-primary m-0 p-0">Instructor</label>
                  </div>
                  <div className="col col-2 text-right">
                    <div>
                      <i className="flaticon-381-add-1"></i>
                    </div>
                  </div>
                </div>
                <UncontrolledCollapse
                  className="w-100"
                  toggler="courseInstructor"
                >
                  <div className="py-4">
                    <Instructors
                      data={instructorData}
                      instructor={instructor}
                      handleChangeMultiBox={multiInstructorSelector}
                    />
                  </div>
                </UncontrolledCollapse>
              </div>
              <div className="w-100 form-group mb-0 ">
                <div className="row mx-0 py-3" id="courseResources">
                  <div className="col col-10 px-0 mx-0">
                    <label className="text-primary m-0 p-0">
                      Resources & Links
                    </label>
                  </div>
                  <div className="col col-2 text-right">
                    <div>
                      <i className="flaticon-381-add-1"></i>
                    </div>
                  </div>
                </div>
                <UncontrolledCollapse
                  className="w-100"
                  toggler="courseResources"
                >
                  <div className="pt-2">
                    <CourseResources course_id={id} />
                  </div>
                </UncontrolledCollapse>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 mb-5">
          <button
            onClick={handleCheckedChange}
            className="w-100 m-0 m-auto btn btn-success"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CourseDetail;
