import React from "react";
import { Multiselect as MultiselectDropdown } from "multiselect-react-dropdown";

const Multiselect = ({ name, options, onSelect, onRemove, selectedValues }) => {
	return <><MultiselectDropdown
		options={options} // Options to display in the dropdown
		displayValue="name"
		isObject={true}
		onSelect={onSelect} // Function will trigger on select event
		onRemove={onRemove} // Function will trigger on remove event
		selectedValues={selectedValues}
	/></>;
}

export default Multiselect;