/* eslint-disable */
import { useState, useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";

import PageTitle from "../../view/layout/PageTitle";
import Loading from "../Common/Loading";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { db } from "../../constants/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";


const UserView = () => {
	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState({});
    const [activeToggle, setActiveToggle] = useState("aboutMe");
	const history = useHistory();
	
	const { id } = useParams();
    const style = {
        'height': 'unset',
      }
    
	useEffect(async () => {
		let isMounted = true;
		setTimeout(() => { setLoaded(true); }, 1000);

		const docRef = doc(db, "users", id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists() && isMounted) {
			const obj = docSnap.data();
            console.log(obj)
			setData(obj);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
		return () => { isMounted = false };
	}, []);

	const handleChange = (e) => {
	};

	return loaded ? (
		<div className="m-0 m-auto px-4 w-100">
           <PageTitle activeMenu="Profile" motherMenu="Users" />
  
           <div className="row">
              <div className="col-lg-12">
                 <div className="profile card card-body p-3">
                    <div className="profile-head">
                       <div className="profile-info">
                          <div className="">
                                {data.avatarURL ?   <img
													    src={data.avatarURL}
													    alt="profile"
                                                        height="70px"
                                                        width="70px"
                                                        className="rounded-circle"
												    /> 
                                                :  
                                                    "" }
                          </div>
                          <div className="profile-details">
                             <div className="profile-name px-3 pt-2">
                                <h4 className="mb-0">
                                   {data.fullname ? data.fullname : data.username}
                                </h4>
                                <p>{data.fullname ? 'Fullname' : 'Username'}</p>
                             </div>
                             <div className="profile-email px-2 pt-2">
                                <h4 className="text-muted mb-0">
                                   {data.email}
                                </h4>
                                <p>Email</p>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
           <div className="row">
              <div className="col-xl-8">
                 <div className="card">
                    <div className="card-body">
                       <div className="profile-tab">
                          <div className="custom-tab-1">
                             <ul className="nav nav-tabs">
                                <li
                                   className="nav-item"
                                   onClick={() => setActiveToggle("aboutMe")}
                                >
                                   <Link
                                      to="#about-me"
                                      data-toggle="tab"
                                      className={`nav-link ${
                                         activeToggle === "aboutMe"
                                            ? "active show"
                                            : ""
                                      }`}
                                   >
                                      About Me
                                   </Link>
                                </li>
                             </ul>
                             <div className="tab-content">
                                <div
                                   id="about-me"
                                   className={`tab-pane fade ${
                                      activeToggle === "aboutMe"
                                         ? "active show"
                                         : ""
                                   }`}
                                >
                                   <div className="profile-about-me">
                                      <div className="pt-4 border-bottom-1 pb-3">
                                         <h4 className="text-primary">
                                            About Me
                                         </h4>
                                         <p className="mb-2">
                                            {data.bio ? data.bio : '-'}
                                         </p>
                                      </div>
                                   </div>
                                   <div className="profile-personal-info">
                                      <h4 className="text-primary mb-4">
                                         Personal Information
                                      </h4>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                               Username
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.username}</span>
                                         </div>
                                      </div>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                               Full Name
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.fullname}</span>
                                         </div>
                                      </div>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                               Email
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.email}</span>
                                         </div>
                                      </div>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                               Phone
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.phone ? data.phone : '-'}</span>
                                         </div>
                                      </div>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                                Date Of Birth
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.dob}</span>
                                         </div>
                                      </div>
                                      <div className="row mb-2">
                                         <div className="col-3">
                                            <h5 className="f-w-500">
                                               Address
                                               <span className="pull-right">
                                                  :
                                               </span>
                                            </h5>
                                         </div>
                                         <div className="col-9">
                                            <span>{data.address ? data.address : '-'}</span>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div className="col-xl-4">
                 <div className="card" style={style}>
                    <div className="card-body">
                     <div className="profile-statistics">
                        <div className="text-center">
                           <div className="row">
                              <div className="col">
                                 <h3 className="m-b-0">{data.followers}</h3>
                                 <span>Followers</span>
                              </div>
                              <div className="col">
                                 <h3 className="m-b-0">{data.following}</h3>
                                 <span>Following</span>
                              </div>
                              <div className="col">
                                 <h3 className="m-b-0">{data.posts}</h3>
                                 <span>Posts</span>
                              </div>
                           </div>
                        </div>
                     </div>
                    </div>
                 </div>
              </div>
           </div>
			{/* <div className="row">
				<div className="col-12 m-0 m-auto">
					<Card>
						<Card.Body>
							<h4>User Details</h4>
							<div className="w-100 form-group mb-0 ">
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">Username:</label>
									<div className="col">
										{data.username}
									</div>
								</div>
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">Full Name:</label>
									<div className="col">
										{data.fullname}
									</div>
								</div>
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">Profile:</label>
									<div className="col">
										{data.avatarURL ? (
											<div className="avatar-container" >
												<img
													src={data.avatarURL}
													alt=""
                                                    width={"80px"}
                                                    height={"80px"}
                                                    className={"rounded-circle"}
												/>{" "}
											</div>
										) : (
											""
										)}
									</div>
								</div>
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">Email:</label>
									<div className="col">
										{data.email}
									</div>
								</div>
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">DOB:</label>
									<div className="col">
                                        {data.dob ? data.dob : '-'}
									</div>
								</div>
								<div className="pt-4 form-group row">
									<label className="col-4 col-form-label">Bio:</label>
									<div className="col">
                                        {data.bio ? data.bio : '-'}
									</div>
								</div>
							</div>
						</Card.Body>
					</Card>
				</div>
			</div> */}
		</div>
	) : (
		<Loading />
	);
};

export default UserView;
