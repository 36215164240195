/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";
import { db, storage, getFileUrl } from "../../constants/firebase";
import { Timestamp } from 'firebase/firestore';
import "../../assets/css/loader.css";
import { Button } from "react-bootstrap";
import Loading from "../Common/Loading";
import { Link } from "react-router-dom";
import {  Card } from "react-bootstrap";
import PageTitle from "../../view/layout/PageTitle";

const Category = () => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [url, setURL] = useState("");
	const [cover, setCover] = useState("");
	const [imageName, setImageName] = useState("");
	const [ishidden, setIshidden] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {
		let isMounted = true;
        setLoaded(false);

		const docRef = doc(db, "categories", id);
		getDoc(docRef).then(docSnap => {
            if (docSnap.exists() && isMounted) {
                const data = docSnap.data();
                setName(data.name);
                setDescription(data.description);
				setImageName(data.cover);
				setIshidden(data.ishidden);
				getFileUrl(data.cover, (url) => {
					setURL(url)
				});
				setLoaded(true); 
            } else {
                console.log("No such document!");
            }
        });
        
		return () => { isMounted = false };
	}, []);

	const handleChange = (e) => {
		switch (e.target.name) {
			case "name":
				setName(e.target.value);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			case "cover":
				setCover(e.target.files[0]);
				break;
			case "ishidden":
				setIshidden(prevCheck => !prevCheck);
				break;
			default:
				return;
		}
	}

    const update = async () => {
        if (name.length > 1 && description.length > 1) {
			let cover_image = '';
			if (cover) {
				cover_image = cover.name;
				const storageRef = ref(storage, cover.name);
				uploadBytes(storageRef, cover).then((snapshot) => {
					console.log('Uploaded a blob or file!');
				});
			}
			const adDocRef = doc(db, 'categories', id)
            await updateDoc(adDocRef, {
                name: name,
                description: description,
				cover: cover_image ? cover_image : imageName,
                active: true,
				ishidden,
                created: Timestamp.now()
            });

            history.push("/learn/categories");
        } else {
            alert('There wasa a problem creating that category. Does one exist with that name?');
        };
    }

	return loaded ? (
		<div className="container m-0 m-auto p-0">
			<div className=" d-none d-sm-block">
				<PageTitle activeMenu={name} motherMenu="Categories" />
			</div>
			<div className="inner">
					<div className={"card p-5 w-lg-50 m-auto text-left"}>
						<div className="form-group">
							<label className="text-black">
								<strong>Category Name</strong>
							</label>
							<input
								type="text"
								className="form-control w-100"
								name="name"
                                defaultValue={name}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="text-black"><strong>Cover Image</strong></label>
								{url ? (
									<div className="avatar-container" >
										<img
											src={url}
											alt=""
											width={"auto"}
											height={"80px"}
										/>{" "}
									</div>
								) : (
									""
								)}
								<input
									type="file"
									onChange={handleChange}
									className="form-control w-100"
									name="cover"
								/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Category Description</strong>
							</label>
							<textarea
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="description"
                                defaultValue={description}
								style={{ resize: "none" }}
								rows="4"
							/>
						</div>
                        <div className="form-group">
                           <div className="custom-control custom-checkbox mb-3">
                              <input
                                 type="checkbox"
                                 className="custom-control-input"
                                 id="customCheckBox1"
								 onChange={handleChange}
								 name="ishidden"
								 checked={ishidden}
                              />
                              <label
                                 className="custom-control-label"
                                 htmlFor="customCheckBox1"
                              >
                                 Is Hidden?
                              </label>
                           </div>
                        </div>
						<Button
							className={"text-uppercase"}
							onClick={update}
						>
							Update
						</Button>
					</div>
			</div>
		</div>
	) :  <Loading />;
};

export default Category;
