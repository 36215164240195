/* eslint-disable */
import React, { useContext } from "react";

/// React router dom
import { useLocation, BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "../view/auth/Login";
import Error404 from "../view/pages/Error404";
import { UserContext } from "../constants/providers/UserProvider";


/// Css
import "../assets/css/index.css";
import "../assets/css/chart.css";

/// Layout
import Nav from "../view/layout/nav";
import Footer from "../view/layout/Footer";
import Registration from "../view/pages/Registration";
import Categories from "../components/Categories/Categories";
import Users from "../components/Users/Users";
import UserView from "../components/Users/UserView";
import careers from "../components/Careers/Jobs";
import PostJob from "../components/Careers/PostJob";
import JobDetail from "../components/Careers/JobDetail";
import Category from "./Categories/CategoryDetail";
import NewCategory from "./Categories/NewCategory";
import Courses from "./Courses/Courses";
import Course from "./Courses/CourseDetail";
import NewCourse from "./Courses/NewCourse";
import AdCampaigns from "./AdCampaigns/AdCampaigns"
import NewCampaign from "./AdCampaigns/NewCampaign"
import CampaignDetails from "./AdCampaigns/CampaignDetails"
import EmailEditor from "./EmailEditor/EmailEditor";
import Editor from "./EmailEditor/Editor";
import TemplateDetail from "./EmailEditor/TemplateDetail";
import System from "./System";
import NewSegment from "./System/NewSegment";

const Markup = () => {

	const user = useContext(UserContext);
	
	const routes = {
		public: [
			/* FIRST ENTRY IS START PAGE */
			{ url: "", component: Login },
		],
		admin: [
			{ url: "", component: Courses },
			{ url: "broadcasts/", component: EmailEditor },
			{ url: "broadcasts/new", component: Editor },
			{ url: "broadcasts/:id", component: TemplateDetail },
			{ url: "users/manage", component: Users },
			{ url: "users/:id", component: UserView },
			{ url: "careers/manage", component: careers },
			{ url: "careers/post", component: PostJob },
			{ url: "careers/:id", component: JobDetail },
			{ url: "learn/categories", component: Categories },
			{ url: "learn/categories/new", component: NewCategory },
			{ url: "learn/categories/:id", component: Category },
			{ url: "learn/courses", component: Courses },
			{ url: "learn/courses/new", component: NewCourse },
			{ url: "learn/courses/:id", component: Course },
			{ url: "ad-campaigns", component: AdCampaigns },
			{ url: "ad-campaigns/new", component: NewCampaign },
			{ url: "ad-campaigns/:id", component: CampaignDetails },
			{ url: "system/manage", component: System },
			{ url: "segments/new", component: NewSegment },
		],
		global: [{ url: "error-404", component: Error404 }],
	};
	
	const path = window.location.pathname;
	const isAuthenticating = (path.indexOf('login') > -1 || path.indexOf('register') > -1) ? true : false;
	const permission = user.role ? user.role : "public";

	return user.initialized ? (
		<Router basename="/">
			<div id="main-wrapper" className={"show menu-toggle-hide"  + (permission === "public" ? " auth" : "")} >
				{permission === "public" ? "" : <Nav isAuthenticating={isAuthenticating} />}
				<div className="content-body">
					<div className="container-fluid">
						<Switch>
							{routes[permission].map((data, i) => (
								<Route
									key={i}
									exact
									path={`/${data.url}`}
									component={data.component}
								/>
							))}
						</Switch>
					</div>
				</div>
				{permission === "public" ? "" :  <Footer />}
			</div>
		</Router>
	) : (
		<>
		</>
	);
};

export default Markup;
