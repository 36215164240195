/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { MultiSelect } from "react-multi-select-component";

import Loading from "../Common/Loading";
import { useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";
import { db, storage, getFileUrl } from "../../constants/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";


const JobDetail = () => {
	const [loaded, setLoaded] = useState(false);
	const [title, setTitle] = useState("");
	const [companyname, setCompanyname] = useState("");
	const [companylogo, setCompanyLogo] = useState(null);
	const [url, setURL] = useState("");
	const [logoName, setLogoName] = useState("");
	const [description, setDescription] = useState("");
	const [applylink, setApplylink] = useState("");
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
    const [jobType, setJobType]= useState([]);
    const [experienceLevel, setExperienceLevel]= useState([]);
	const [status, setStatus] = useState("active");
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');
	const [region, setRegion] = useState('');
	const [radius, setRadius] = useState('');
	const today = new Date();
	const defaultExpirationDate = new Date();
	defaultExpirationDate.setDate(today.getDate() + 89);
	const history = useHistory();
	const selectionRange = {
		startDate: today,
		endDate: defaultExpirationDate,
		key: "activeDates",
	};
	const [activesDates, setActiveDates] = useState([selectionRange]);
	const { id } = useParams();

	useEffect(async () => {
		let isMounted = true;
		setTimeout(() => { setLoaded(true); }, 1000);

		const docRef = doc(db, "jobs", id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists() && isMounted) {
			const data = docSnap.data();
			setTitle(data.title);
			setCompanyname(data.companyname);
			setDescription(data.description);
			setApplylink(data.applylink);
			setJobType(data.jobType ? data.jobType : []);
			setExperienceLevel(data.experienceLevel ? data.experienceLevel : []);
			setFrom(data.from);
			setTo(data.to);
			setStatus(data.status);
			data.activesDates[0].startDate = new Date(data.activesDates[0].startDate.seconds * 1000)
			data.activesDates[0].endDate = new Date(data.activesDates[0].endDate.seconds * 1000)
			setActiveDates(data.activesDates);
			setLogoName(data.logoName);
			setCountry(data.country);
			setZip(data.zip);
			setRegion(data.region);
			setRadius(data.radius);
			getFileUrl(data.logoName, (url) => {
				setURL(url)
			});
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
		return () => { isMounted = false };
	}, []);

	const jobtype_options = [
        { label: 'Full Time', value: 'Full Time' },{ label: 'Hourly', value: 'Hourly' },
        { label: 'Part-time', value: 'Part-time' },
        { label: 'Remote', value: 'Remote' },
        { label: 'Internship', value: 'Internship' },
        { label: 'Freelance', value: 'Freelance' },
	];

	const explvl_options = [
        { label: 'Entry Level', value: 'Entry Level' },
        { label: 'Mid Level', value: 'Mid Level' },
        { label: 'Manager Level', value: 'Manager Level' },
        { label: 'Sr. Manager Level', value: 'Sr. Manager Level'},
	];

    const handleChangeJobType = (values) => {
        setJobType(values);
    };

    const handleChangeExpLvl = (values) => {
        setExperienceLevel(values);
    };

	/* function to update document in firestore */
	const handleCheckedChange = async () => {
		const jobDocRef = doc(db, 'jobs', id)
		try {

			let logo_name = '';
			if (companylogo) {
				logo_name = companylogo.name;
				const storageRef = ref(storage, companylogo.name);
				uploadBytes(storageRef, companylogo).then((snapshot) => {
					console.log('Uploaded a blob or file!');
				});
			}
			await updateDoc(jobDocRef, {
				title: title,
				companyname: companyname,
				description: description,
				applylink: applylink,
				from,
				to,
				jobType,
				experienceLevel,
				status: status,
				activesDates,
				logoName: logo_name ? logo_name : logoName,
				country,
				zip,
				region,
				radius
			});

			history.push("/careers/manage");
		} catch (err) {
			alert(err)
		}
	}

	const handleChange = (e) => {
		switch (e.target.name) {
			case "title":
				setTitle(e.target.value);
				break;
			case "companyname":
				setCompanyname(e.target.value);
				break;
			case "companylogo":
				setCompanyLogo(e.target.files[0]);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			case "applylink":
				setApplylink(e.target.value);
				break;
			case "from":
				setFrom(e.target.value);
				break;
			case "to":
				setTo(e.target.value);
				break;
			case "status":
				setStatus(e.target.value);
				break;
			case "country":
				setCountry(e.target.value);
				break;
			case "zip":
				setZip(e.target.value);
				break;
			case "region":
				setRegion(e.target.value);
				break;
			case "radius":
				setRadius(e.target.value);
				break;
			default:
				return;
		}
	};

	// const onSlide = (render, handle, value, un, percent) => {
	// 	setSalaryRange({
	// 		min: value[0],
	// 		max: value[1],
	// 	})
	// };

	return loaded ? (
    <div className="m-0 m-auto px-4 w-100">
      <div className="row">
        <div className="col-12 m-0 m-auto">
          <Card>
            <Card.Body>
              <h4>Job Details</h4>
              <div className="w-100 form-group mb-0 ">
                <div className="pt-4 form-group row">
                  <label className="col-4 col-form-label">Job Title</label>
                  <div className="col">
                    <input
                      className="form-control w-100 fs-12"
                      type="text"
                      onChange={handleChange}
                      defaultValue={title}
                      name="title"
                    />
                  </div>
                </div>
                <div className="pt-4 form-group row">
                  <label className="col-4 col-form-label">Company Logo</label>
                  <div className="col">
                    {url ? (
                      <div className="avatar-container">
                        <img src={url} alt="" width={"auto"} height={"80px"} />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      onChange={handleChange}
                      className="form-control w-100"
                      name="companylogo"
                    />
                  </div>
                </div>
                <div className="pt-4 form-group row">
                  <label className="col-4 col-form-label">Company Name</label>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control w-100 fs-12"
                      onChange={handleChange}
                      defaultValue={companyname}
                      name="companyname"
                    />
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Description</label>
                  <div className="col">
                    <textarea
                      className="p-2 mt-2 form-control w-100 fs-12"
                      style={{ height: "300px" }}
                      onChange={handleChange}
                      defaultValue={description}
                      name="description"
                    ></textarea>
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Job Type</label>
                  <div className="col">
                    <MultiSelect
                      options={jobtype_options}
                      value={jobType}
                      onChange={handleChangeJobType}
                      labelledBy="Job Type"
                      name="jobtype"
                    />
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">
                    Experience Level
                  </label>
                  <div className="col">
                    <MultiSelect
                      options={explvl_options}
                      value={experienceLevel}
                      onChange={handleChangeExpLvl}
                      labelledBy="Experience Level"
                      name="experienceLevel"
                    />
                  </div>
                </div>
				<div className="pt-2 form-group row">
					<label className="col-4 col-form-label">
						Salary Range
					</label>
					<div className="col">
						<div className="row">
							<div class="col">
								<div className="input-group mb-3 input-primary">
									<div className="input-group-prepend">
										<span className="input-group-text">
											$
										</span>
									</div>
									<input
										type="text"
										className="form-control fs-12"
										onChange={handleChange}
										defaultValue={from}
										name="from"
										placeholder="From"
									/>
									<div className="input-group-append">
										<span className="input-group-text">
											K
										</span>
									</div>
								</div>
							</div>
							<div class="col">
								<div className="input-group mb-3 input-primary">
									<div className="input-group-prepend">
										<span className="input-group-text">
											$
										</span>
									</div>
									<input
										type="text"
										className="form-control fs-12"
										onChange={handleChange}
										defaultValue={to}
										name="to"
										placeholder="To"
									/>
									<div className="input-group-append">
										<span className="input-group-text">
											K
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Apply Link</label>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control w-100"
                      onChange={handleChange}
                      defaultValue={applylink}
                      name="applylink"
                    />
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Status:</label>
                  <div className="col">
                    <select
                      className="form-control"
                      id="sel1"
                      defaultValue={status}
                      name="status"
                      onChange={handleChange}
                    >
                      <option value="active">Active</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Active Dates</label>
               
                    <div className="col col-8 w-100">
											<DateRangePicker
										
											className="w-100"
                        onChange={(item) => setActiveDates([item.activeDates])}
                        ranges={activesDates}
                        months={2}
                        showSelectionPreview={true}
                        direction="vertical"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                      />
                   
                  </div>
                </div>
                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Country</label>
                  <div className="col">
                    <input
                      type="text"
                      defaultValue={country}
                      onChange={handleChange}
                      className="form-control w-100"
                      name="country"
                    />
                  </div>
                </div>

                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">
                    Zip/Postal Code
                  </label>
                  <div className="col">
                    <input
                      type="text"
                      defaultValue={zip}
                      onChange={handleChange}
                      className="form-control w-100"
                      name="zip"
                    />
                  </div>
                </div>

                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Region/State</label>
                  <div className="col">
                    <input
                      type="text"
                      defaultValue={region}
                      onChange={handleChange}
                      className="form-control w-100"
                      name="region"
                    />
                  </div>
                </div>

                <div className="pt-2 form-group row">
                  <label className="col-4 col-form-label">Radius</label>
                  <div className="col">
                    <input
                      type="text"
                      defaultValue={radius}
                      onChange={handleChange}
                      className="form-control w-100"
                      name="radius"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div>
        <div className="w-100 text-center mb-5">
          <button
            onClick={handleCheckedChange}
            className="w-50 m-0 m-auto btn btn-success"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default JobDetail;
