/* eslint-disable */
import React, { useState } from "react";
import { Table, Card, ButtonGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../Common/Loading";
import { db } from "../../constants/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import swal from 'sweetalert';
import MaterialTable from '@material-table/core';
import TableIcons from "../Common/TableIcons";

import {
	collection,
	query,
	orderBy,
	startAfter,
	endBefore,
	limit,
	onSnapshot,
	limitToLast,
} from "firebase/firestore";

const style = {
	width: "40%",
};

const UserList = ({ users, setUsers, page, setPage }) => {
	const [loaded, setLoaded] = useState(false);

	//next list
	const showNext = ({ item }) => {
		if (users.length === 0) {
			alert("Thats all we have for now !");
		} else {
			setLoaded(true);
			const fetchNextData = () => {
				const next = query(
					collection(db, "users"),
					orderBy("createdAt", "desc"),
					startAfter(item.createdAt),
					limit(15)
				);
				onSnapshot(next, (querySnapshot) => {
					setUsers(
						querySnapshot.docs.map((doc) => ({
							id: doc.id,
							data: doc.data(),
						}))
					);
					setPage(page + 1);
					setLoaded(false);
				});
			};
			fetchNextData();
		}
	};

	//previous list
	const showPrevious = ({ item }) => {
		setLoaded(true);
		const fetchPreviousData = () => {
			const previous = query(
				collection(db, "users"),
				orderBy("createdAt", "desc"),
				endBefore(item.createdAt),
				limitToLast(15)
			);
			onSnapshot(previous, (querySnapshot) => {
				setUsers(
					querySnapshot.docs.map((doc) => ({
						id: doc.id,
						data: doc.data(),
					}))
				);
				setPage(page - 1);
				setLoaded(false);
			});
		};
		fetchPreviousData();
	};

	const handleSwal = async (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this user!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then(async (willDelete) => {
				if (willDelete) {
					const userDocRef = doc(db, 'users', id)
					try {
						await deleteDoc(userDocRef)
					} catch (err) {
						alert(err)
					}
				}
			});
	}

	return (
		<MaterialTable
			title="Users"
			icons={TableIcons}
			actions={[
				{
					icon: TableIcons.Delete,
					tooltip: 'Delete User',
					onClick: (event, rowData) => {
						handleSwal(rowData.id);
					}
				}
			]}
			data={users}
			columns={[
				{ title: 'Avatar', render: rowData => rowData.data.avatarURL ? <img src={rowData.data.avatarURL} style={{ width: 50, borderRadius: '50%' }} /> : '-' },
				{ title: 'Username', field: 'data.username', render: rowData => 	<Link to={`/users/${rowData.id}`}>{rowData.data.username}{" "}</Link> },
				{ title: 'Email', field: 'data.email' },
			]}
			// other props
			options={{
				search: true,
				sorting: true,
				actionsColumnIndex: -1.,
				pageSize: 10
			}}
		/>
		// <Card>
		// 	<Card.Body>
		// 		<Card.Title>Users </Card.Title>
		// 		<div className="w-100 mx-0 mx-md-auto row justify-content-center">
		// 			<div id="list" className="display w-100 dataTable">
		// 				<div id="list_wrapper" className="dataTables_wrapper">
		// 					{users.length > 0 ? (
		// 						<Table id="list" className="display w-100 dataTable">
		// 								<thead>
		// 									<tr role="row">
		// 										<th>Avatar</th>
		// 										<th style={style}>Username</th>
		// 										<th>Email</th>
		// 										<th>Action</th>
		// 									</tr>
		// 								</thead>
		// 								<tbody>
		// 									{loaded ? (
		// 										<tr>
		// 											<td colSpan={"3"}>
		// 												<Loading />{" "}
		// 											</td>
		// 										</tr>
		// 									) : (
		// 										users.map((user, i) => {
		// 											const data = user.data;
		// 											return (
		// 												<tr key={i + "qli"}>
		// 													<td>
		// 														{data.avatarURL ? (
		// 															<img
		// 																src={data.avatarURL}
		// 																alt=""
		// 																width={"80px"}
		// 																height={"80px"}
		// 																className="rounded-circle"
		// 															/>
		// 														) : (
		// 															"-"
		// 														)}
		// 													</td>
		// 													<td>
		// 														<Link to={`/users/${user.id}`}>
		// 															{data.username}{" "}
		// 														</Link>
		// 													</td>
		// 													<td>{data.email}</td>
		// 													<td>
		// 														<button className="btn btn-primary" onClick={() => handleSwal(user.id)}>
		// 															Delete
		// 														</button>
		// 													</td>
		// 												</tr>
		// 											);
		// 										})
		// 									)}
		// 								</tbody>
		// 							</Table>
		// 					) : (
		// 						<h5 className="text-muted mt-4">No data to display.</h5>
		// 					)}
		// 					<ButtonGroup>
		// 						{
		// 							//show previous button only when we have items
		// 							page === 1 ? (
		// 								""
		// 							) : (
		// 								<Button
		// 									onClick={() => showPrevious({ item: users[0].data })}
		// 									style={{ marginRight: "6px" }}
		// 								>
		// 									Previous
		// 								</Button>
		// 							)
		// 						}

		// 						{
		// 							//show next button only when we have items
		// 							users.length < 15 || users.length === 0 ? (
		// 								""
		// 							) : (
		// 								<Button
		// 									onClick={() =>
		// 										showNext({ item: users[users.length - 1].data })
		// 									}
		// 								>
		// 									Next
		// 								</Button>
		// 							)
		// 						}
		// 					</ButtonGroup>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</Card.Body>
		// </Card>
	);
};

export default UserList;
