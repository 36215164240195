/* eslint-disable */
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
} from "react";
import { auth, UserController, db } from "../firebase";
import useLocalStorage from "../hooks/useLocalStorage";
import { onAuthStateChanged } from "firebase/auth";
import {
  setDoc,
  doc,
  getDocs,
  collection,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
export const UserContext = createContext({ user: null });

const UserProvider = (props) => {
  const [user, setUser] = useState({ initialized: false });

  // eslint-disable-next-line
  const [formObj, setFormObj] = useLocalStorage("form_obj", user);
  const userRef = useRef(user);
  /* eslint-disable */
  // const setFormObjRef = useRef(setFormObj);

  const uid_gen = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const doesUserExist = async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (result) => {
      if (result) {
        //user details
        const { displayName, email, photoURL, createdAt, uid } = result;

        //create user object
        const userData = {
          fullname: displayName,
          username: displayName.split(" ")[0],
          address: "",
          bio: "",
          dob: "",
          referralCode: uid_gen(),
          uid: uid,
          followers: 0,
          following: 0,
          posts: 0,
          notificationDisplay: true,
          website: "",
          email: email,
          role: "admin",
          avatarURL: photoURL ? photoURL : "https://i.imgur.com/ff8fLij.png",
          createdAt: Timestamp.now(),
        };
        let checkUserExist = await doesUserExist(email);

        if (!checkUserExist) {
          console.log("entry");
          await setDoc(doc(db, "users", uid), userData);
          console.log("Document successfully written!");
        }

        const profile = userData;
        const isAdmin =
          email.includes("maiko@maiko.company") ||
          (profile.role && profile.role.toLowerCase() === "admin") ||
          email.includes("@weld.com") ||
          email.includes("amberdayrdh@gmail.com")
            ? true
            : false;

        let d = { displayName, email, photoURL, createdAt };
        d.role = isAdmin ? "admin" : "user";

        d.fullname = displayName;
        d.username = displayName.split(" ")[0];
        userRef.current = {
          ...userRef.current,
          ...d,
        };

        if (profile) {
          userRef.current = { ...userRef.current, ...profile };
        }
      }

      setUser({ ...userRef.current, ...{ initialized: true } });
    });
  }, []);

  useEffect(() => {
    /* eslint-disable */
    setFormObj(user);
  }, [user]);
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
