/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { MultiSelect } from "react-multi-select-component";

import { Button, Form } from "react-bootstrap";
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, storage } from "../../constants/firebase";
import { uploadBytes, ref } from "firebase/storage";

const PostJob = () => {
	const [title, setTitle] = useState("");
	const [companyname, setCompanyname] = useState("");
	const [companylogo, setCompanyLogo] = useState(null);
	const [description, setDescription] = useState("");
	const [applylink, setApplylink] = useState("");
	const [status, setStatus] = useState("active");
	const [country, setCountry] = useState('');
	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");
    const [jobType, setJobType]= useState([]);
    const [experienceLevel, setExperienceLevel]= useState([]);
	const [region, setRegion] = useState('');
	const [radius, setRadius] = useState('');
	const [zip, setZip] = useState('');

	const today = new Date();
	const defaultExpirationDate = new Date();
	defaultExpirationDate.setDate(today.getDate() + 89);
	const history = useHistory();
	const selectionRange = {
		startDate: today,
		endDate: defaultExpirationDate,
		key: "activeDates",
	};
	const [activesDates, setActiveDates] = useState([selectionRange]);

	const jobtype_options = [
        { label: 'Full Time', value: 'Full Time' },{ label: 'Hourly', value: 'Hourly' },
        { label: 'Part-time', value: 'Part-time' },
        { label: 'Hourly', value: 'Hourly' },
        { label: 'Remote', value: 'Remote' },
        { label: 'Internship', value: 'Internship' },
        { label: 'Freelance', value: 'Freelance' },
	];

	const explvl_options = [
        { label: 'Entry Level', value: 'Entry Level' },
        { label: 'Mid Level', value: 'Mid Level' },
        { label: 'Manager Level', value: 'Manager Level' },
        { label: 'Sr. Manager Level', value: 'Sr. Manager Level'},
	];

    const handleChangeJobType = (values) => {
        setJobType(values);
    };

    const handleChangeExpLvl = (values) => {
        setExperienceLevel(values);
    };

	const handleChange = (e) => {
		switch (e.target.name) {
			case "title":
				setTitle(e.target.value);
				break;
			case "companyname":
				setCompanyname(e.target.value);
				break;
			case "companylogo":
				setCompanyLogo(e.target.files[0]);
				break;
			case "description":
				setDescription(e.target.value);
				break;
			case "status":
				setStatus(e.target.value);
				break;
			case "from":
				setFrom(e.target.value);
				break;
			case "to":
				setTo(e.target.value);
				break;
			case "applylink":
				setApplylink(e.target.value);
				break;
			case "country":
				setCountry(e.target.value);
				break;
			case "zip":
				setZip(e.target.value);
				break;
			case "region":
				setRegion(e.target.value);
				break;
			case "radius":
				setRadius(e.target.value);
				break;
			default:
				return;
		}
	};

	const handleCreate = async () => {
		//companylogo
		let logo_name = '';
		if (companylogo) {
			logo_name = companylogo.name;
			const storageRef = ref(storage, companylogo.name);
			uploadBytes(storageRef, companylogo).then((snapshot) => {
				console.log('Uploaded a blob or file!');
			});
		}

		if (title.length > 1 && companyname.length > 1 && description.length > 1 && applylink.length > 1) {
			await addDoc(collection(db, 'jobs'), {
				title: title,
				companyname: companyname,
				description: description,
				logoName: logo_name,
				applylink: applylink,
				status: status,
				activesDates,
				active: true,
				country,
				zip,
				region,
				radius,
				from,
				to,
				jobType,
				experienceLevel,
				created: Timestamp.now()
			});
			history.push("/careers/manage");
		} else {
			alert('There wasa a problem creating that job. Does one exist with that title?');
		};
	}

	// const onSlide = (render, handle, value, un, percent) => {
	// 	setSalaryRange({
	// 		min: value[0],
	// 		max: value[1],
	// 	})
	// };

	return (
		<Fragment>
			<div className="justify-content-center  align-items-center h-80 w-100">
				<div className="form-input-content text-center error-page">
					<h1 className="font-weight-bold mb-5">Post a Job</h1>
					<div className={"card p-5  w-lg-50  m-auto text-left"}>
						<div className="form-group">
							<label className="text-black">
								<strong>Job Title</strong>
							</label>
							<input
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="title"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Company Name</strong>
							</label>
							<input
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="companyname"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Company Logo</strong>
							</label>
							<input
								type="file"
								onChange={handleChange}
								className="form-control w-100"
								name="companylogo"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Job description</strong>
							</label>
							<textarea
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="description"
								style={{ resize: "none" }}
								rows="4"
							/>
						</div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Job Type</strong>
                            </label>
                            <MultiSelect
                                options={jobtype_options}
                                value={jobType}
                                onChange={handleChangeJobType}
                                labelledBy="Job Type"
                                name="jobtype"
                            />
                        </div>
                        <div className="form-group">
                            <label className="text-black">
                                <strong>Experience Level</strong>
                            </label>
                            <MultiSelect
                                options={explvl_options}
                                value={experienceLevel}
                                onChange={handleChangeExpLvl}
                                labelledBy="Experience Level"
                                name="experienceLevel"
                            />
                        </div>
						<div className="form-group">
							<label className="text-black">
								<strong>Salary Range</strong>
							</label>
							<div className="row">
								<div class="col">
									<div className="input-group mb-3 input-primary">
										<div className="input-group-prepend">
											<span className="input-group-text">
												$
											</span>
										</div>
										<input
											type="text"
											className="form-control fs-12"
											onChange={handleChange}
											defaultValue={from}
											name="from"
											placeholder="From"
										/>
										<div className="input-group-append">
											<span className="input-group-text">
												K
											</span>
										</div>
									</div>
								</div>
								<div class="col">
									<div className="input-group mb-3 input-primary">
										<div className="input-group-prepend">
											<span className="input-group-text">
												$
											</span>
										</div>
										<input
											type="text"
											className="form-control fs-12"
											onChange={handleChange}
											defaultValue={to}
											name="to"
											placeholder="To"
										/>
										<div className="input-group-append">
											<span className="input-group-text">
												K
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Apply Link</strong>
							</label>
							<input
								type="text"
								onChange={handleChange}
								className="form-control w-100"
								name="applylink"
							/>
						</div>
						<div className="form-group">
							<label className="text-black">Status:</label>
							<select className="form-control" id="sel1" defaultValue={status} name="status" onChange={handleChange}>
								<option value="active">Active</option>
								<option value="deactive">Deactive</option>
							</select>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Active Dates</strong>
							</label> 
							<div className="">
								<DateRangePicker
									onChange={item => setActiveDates([item.activeDates])}
									ranges={activesDates}
									months={2}
									showSelectionPreview={true}
									direction="vertical"
									preventSnapRefocus={true}
									calendarFocus="backwards"
								/>
							</div>
						</div>
						<div className="form-group">
							<label className="text-black">
								<strong>Country</strong>
							</label>
							<input type="text" onChange={handleChange} className="form-control w-100" name="country" />
						</div>

						<div className="form-group">
							<label className="text-black">
								<strong>Zip/Postal Code</strong>
							</label>
							<input type="text" onChange={handleChange} className="form-control w-100" name="zip" />
						</div>

						<div className="form-group">
							<label className="text-black">
								<strong>Region/State</strong>
							</label>
							<input type="text" onChange={handleChange} className="form-control w-100" name="region" />
						</div>

						<div className="form-group">
							<label className="text-black">
								<strong>Radius</strong>
							</label>
							<input type="text" onChange={handleChange} className="form-control w-100" name="radius" />
						</div>
						<Button onClick={handleCreate} className={"text-uppercase mt-4"}>
							Create
						</Button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PostJob;
