/* eslint-disable */
import * as firebase from "firebase/app";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, setDoc, doc, getDoc, getDocs, collection, query, Timestamp, where } from 'firebase/firestore';


const firebaseConfig = {
	apiKey: "AIzaSyCVf_oPGExEncax2IPG9kdQjhyMud0ENUI",
	authDomain: "weld-app.firebaseapp.com",
	databaseURL: "https://weld-app-default-rtdb.firebaseio.com",
	projectId: "weld-app",
	storageBucket: "weld-app.appspot.com",
	messagingSenderId: "480374351905",
	appId: "1:480374351905:web:193e5a68e58ea352135b60",
};

// Initialize Firebase
const app =
  firebase.getApps().length === 0
    ? firebase.initializeApp(firebaseConfig)
    : (firebase.getApps())[0];

const db = getFirestore(app);

const auth = getAuth()
const googleProvider = new GoogleAuthProvider();

const storage = getStorage(app);

const upload = (file, type) => {
	if (file == null) return;
	storage
		.ref(`/` + type + `/${file.name}`)
		.put(file)
		.on("state_changed", alert("success"), alert);
};

const getFileUrl = (filename, callback) => {
	if (filename) {
		var ref_m = ref(storage, filename);
		getDownloadURL(ref_m).then(url => {
			callback(url);
		});
	} else {
		callback('')
	}
}
const uid = function () {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return '_' + Math.random().toString(36).substr(2, 9);
};

const doesUserExist = async (email) => {
	const q = query(collection(db, "users"), where("email", "==", email));
	const querySnapshot = await getDocs(q);
	if (querySnapshot.empty) {
		return false;
	}
	return true;
};

const signInWithGoogle = () => {
	signInWithPopup(auth, googleProvider)
		.then(async (result) => {

			// This gives you a Google Access Token. You can use it to access the Google API.
			// const credential = GoogleAuthProvider.credentialFromResult(result);
			// const token = credential.accessToken;
			// The signed-in user info.
			const user = result.user;
			console.log('signin successfully');
			// ...
		}).catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			// The email of the user's account used.
			const email = error.email;
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error);
			// ...
		});
};

const UserController = {
	getAllUsers: async () => {
		const q = query(collection(db, "users"));
		const snapshot = await getDocs(q);
		let s = [];
		snapshot.forEach((doc) => {
			let u = doc.data();
			u.uid = doc.id;
			u.id = doc.id;
			if (u.is_submited) {
				s.push(u);
			}
			
			return doc.data();
		});
		localStorage.setItem("member_total", s.length);

		return s;
	},
	getUser: async (id) => {
		const q = doc(db, "users", id);
		const docRef = await getDoc(q);
		console.log(docRef.exists())
		if (!docRef.exists) {
			return false
		} else {
			return docRef.data();
		}
	},
	isLoggedIn: async () => {
		let x = auth.currentUser;
		console.log(
			"Init with user at " +
			(auth.currentUser ? auth.currentUser.email : " NONE")
		);
		return x;
	},
};

export const logOut = () => {
	return auth
		.signOut()
		.then(() => {
			window.location.href = "/";
			return true;
		})
		.catch((error) => {
			return false;

		});
};

export { app, db, upload, storage, getFileUrl, auth, signInWithGoogle, UserController }
