/* eslint-disable */
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from "../../constants/firebase";
import Loading from "../Common/Loading";

import Resources from "./Resources";

/*
Type: "Test"	
Lesson #:
Segment #:
Title: 
Start Time: 
End Time: 
Description:
Resources:

{type:"segment", lesson:"1", segment: "2", title:"join preperation", start:"0", end:"60", description:"prepare joints" resources:[]}

*/
const NewLessonSegment = ({ lessons, show, onClose, segmentId, setShowAddSegmentModal }) => {

	const [segment, setSegment] = useState({
		lesson_id: '',
		title: '',
		description: '',
		type: '',
		number: '',
		lesson: '',
		minutes: '',
		seconds: '',
		resources: [],
	});
	const [loaded, setLoaded] = useState(true);

	const { id } = useParams();

	const handleChange = (e) => {
		setSegment(prevState => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	}


	useEffect(async () => {

		let isMounted = true;
		setLoaded(false);

		if (segmentId) {
			//On load get lesson
			const docRef = doc(db, `courses/${id}/segments`, segmentId);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists() && isMounted) {
				const data = docSnap.data();
				setSegment(data);

			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		}
		setTimeout(() => { setLoaded(true); }, 1000);

		return () => { isMounted = false };
	}, []);

	return (
    <Modal
      show={show}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      centered
    >
      {loaded ? (
        <>
          <Modal.Header onHide={onClose} closeButton>
            <Modal.Title>
              Segment{" "}
              <span className="warning text-muted small">
                All fields required
              </span>
            </Modal.Title>
          </Modal.Header>{" "}
          <Modal.Body>
            <div className="form-group">
              <label>Parent Lesson</label>
              <select
                className="form-control"
                onChange={handleChange}
                name="lesson_id"
                value={segment.lesson_id}
              >
                <option value="">Select a lesson</option>
                {lessons.map((raw, index) => {
                  return (
                    <option key={index} value={raw.id}>
                      {raw.data.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group row">
              <div className="col-2">
                <label>#</label>
                <input
                  onChange={handleChange}
                  value={segment.number}
                  name="number"
                  className="form-control"
                  type="number"
                />
              </div>
              <div className="col-10">
                <label>Segment Title</label>
                <input
                  className="form-control"
                  value={segment.title}
                  onChange={handleChange}
                  name="title"
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                className="form-control"
                value={segment.description}
                onChange={handleChange}
                name="description"
              ></input>
            </div>
            <div className="text-muted mb-2 w-100 text-center">Segment Startime</div>
            <div className="form-group row text-center">
              <div className="col col-6">
                <label>Minutes</label>
                <input
                  onChange={handleChange}
                  value={segment.minutes}
                  name="minutes"
                  className="form-control text-center"
                  placeholder="ex. 2 (2 minutes)"
                  type="number"
                />{" "}
              </div>{" "}
              <div className="col col-6">
                <label>Seconds</label>
                <input
                  onChange={handleChange}
                  value={segment.seconds}
                  name="seconds"
                  className="form-control text-center"
                  placeholder="ex. 35 (...and 35 seconds)"
                  type="number"
                />{" "}
              </div>
            </div>
            <div className="form-group py-0 my-0">
              <label>Resources</label>
              <div className="pt-2">
                <Resources
                  course_id={id}
                  segment={segment}
                  setSegment={setSegment}
                  segmentId={segmentId}
                  setShowAddSegmentModal={setShowAddSegmentModal}
                />
              </div>
            </div>
          </Modal.Body>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
const Lesson = () => { };

export { NewLessonSegment };
